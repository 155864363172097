import { SIZE } from 'baseui/select';
import ReactSelect from 'react-select';
import { useSelectedCompanies } from 'context/companies';
import 'react-datepicker/dist/react-datepicker.css';
import { selectMonth, selectPeriod } from 'modules/shared/constant';
import { useEffect, useRef, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { DatePicker } from 'baseui/datepicker';
import informationIcon from 'assets/icons/infomation_i_icon.png';

interface SelectType {
  label: string;
  id: string;
}

interface AllFiltersProps {
  setSelectedPeriod?: React.Dispatch<React.SetStateAction<SelectType | null>>;
  selectedPeriod?: SelectType | null;
  setSelectedYear?: any;
  selectedYear?: any;
  setSelectedMonth?: any;
  selectedMonth?: any;
  setSelectedWeeks?: any;
  selectedWeeks?: any;
  fitlerLabel?: boolean;
  applyFilter?: any;
  weeksOptions?: any;
  weeksOptionsForApi?: any;
  handleYearChange?: any;
  shouldShowButtons?: any;
  shouldShowIcon?: any;
  salesLastFetchedAt?: any;
  cogsLastFetchedAt?: any;
}

const AllFilters = ({
  setSelectedPeriod,
  selectedPeriod,
  setSelectedYear,
  selectedYear,
  setSelectedMonth,
  selectedMonth,
  setSelectedWeeks,
  selectedWeeks,
  fitlerLabel,
  applyFilter,
  weeksOptions,
  weeksOptionsForApi,
  handleYearChange,
  shouldShowButtons = true,
  shouldShowIcon = false,
  salesLastFetchedAt,
  cogsLastFetchedAt,
}: AllFiltersProps) => {
  const [css] = useStyletron();
  const { companiesOptions } = useSelectedCompanies();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleToggleClick = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [selectedDate, setSelectedDate] = useState<Date | Date[] | null>(null);
  const [minYear, setMinYear] = useState<number>(new Date().getFullYear());
  const [maxYear, setMaxYear] = useState<number>(new Date().getFullYear());

  const [yearOptions, setYearOptions] = useState<any>([]);

  const getLowestYear = (companies: any) => {
    const years = companies
      .map((company: any) => company.year)
      .filter((year: any) => year !== null && year !== undefined); // Filter out null and undefined

    // If no valid years are present, return the current year
    if (years.length === 0) {
      return new Date().getFullYear();
    }

    return Math.min(...years);
  };

  const generateYearOptions = (startYear: any) => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];

    for (let year = startYear; year <= currentYear; year++) {
      yearOptions.push({ id: year, label: year.toString() });
    }

    return yearOptions;
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  // Handle DatePicker change
  const handleDateChange = (date: Date | Date[] | null) => {
    let dateInput: Date | null = null;
    setSelectedDate(date);
    if (Array.isArray(date)) {
      dateInput = date[0];
    } else {
      dateInput = date;
    }

    if (dateInput) {
      const formattedDate = formatDate(dateInput);

      const selectedYear = dateInput.getFullYear();
      const selectedMonth = dateInput.getMonth() + 1;

      if (setSelectedMonth) {
        setSelectedYear({ id: selectedYear, label: selectedYear.toString() });

        setSelectedMonth({
          id: selectedMonth,
          label: selectMonth.find((month) => month.id === String(selectedMonth))
            ?.label,
        });
      }

      if (selectedPeriod?.id === 'week') {
        handleYearChange(selectedYear, formattedDate);
      }
    } else {
      if (setSelectedYear) setSelectedYear(null);
      if (setSelectedMonth) setSelectedMonth(null);
      if (setSelectedWeeks) setSelectedWeeks(null);
    }
  };

  useEffect(() => {
    if (companiesOptions.length > 0) {
      // Step 1: Find the lowest year from selected companies
      const lowestYear = getLowestYear(companiesOptions);
      const currentYear = new Date().getFullYear();
      setMinYear(lowestYear);
      setMaxYear(currentYear);
      // Step 2: Generate year options from the lowest year to the current year
      const options = generateYearOptions(lowestYear);
      setYearOptions(options);
    }
  }, [companiesOptions]);

  const filterLabel = css({
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: '400',
    color: 'var(--black)',
    margin: '0 0 8px',
  });

  const buttonContainerStyle = css({
    display: 'inline-flex', // Allow items to appear inline
    alignItems: 'center',
    position: 'relative', // For dropdown positioning
  });

  const buttonStyle = css({
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: '0px 12px',
    backgroundColor: 'var(--primary)',
    color: 'black',
    cursor: 'pointer',
    border: '2px solid #A9A9A9',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    gap: '4px',
    height: '41px',
    transition: 'all 0.3s ease',
    ':hover': {
      backgroundColor: 'var(--darkPrimary)',
      borderColor: 'var(--darkPrimary)',
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.4)',
    },
  });

  const dropdownStyle = css({
    position: 'absolute',
    top: '100%',
    left: '0',
    backgroundColor: '#fff',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    zIndex: 999,
    padding: '8px 0',
    minWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
  });

  const dropdownItemStyle = css({
    padding: '8px 12px',
    fontSize: '12px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: 'var(--lightGray)',
    },
  });

  const toggleStyle = css({
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0 4px',
    fontSize: '18px',
    lineHeight: '1',
    color: 'var(--black)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      color: 'var(--darkPrimary)',
    },
  });
  return (
    <>
      <div>
        {fitlerLabel && (
          <label className={css({ fontSize: '16px', marginBottom: '8px' })}>
            Date Period
          </label>
        )}
        <ReactSelect
          options={selectPeriod.map(({ id, label }: any) => ({
            label,
            value: id,
          }))}
          value={
            selectedPeriod
              ? { label: selectedPeriod.label, value: selectedPeriod.id }
              : null
          }
          placeholder="Select Period"
          isClearable={false}
          onChange={(selectedOption) => {
            setSelectedDate(null);
            setSelectedPeriod?.(
              selectedOption
                ? { label: selectedOption.label, id: selectedOption.value }
                : null
            );
          }}
          isSearchable={false}
          menuPlacement="auto" // Dropdown placement
          menuShouldScrollIntoView={false} // Prevents scrolling the page to bring the dropdown into view
          styles={{
            container: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999, // High zIndex for the portal
            }),
          }}
        />
      </div>
      {selectedPeriod && (
        <div className="multi-selectbox">
          {fitlerLabel && <label className={filterLabel}>Year</label>}
          <ReactSelect
            options={yearOptions.map(({ id, label }: any) => ({
              label,
              value: id,
            }))}
            value={
              Object.keys(selectedYear).length > 0
                ? { label: selectedYear.label, value: selectedYear.id }
                : null
            }
            placeholder="Select Year"
            isDisabled={false}
            onChange={(selectedOption) => {
              setSelectedDate(null);
              setSelectedYear?.(
                selectedOption
                  ? { label: selectedOption.label, id: selectedOption.value }
                  : null
              );
            }}
            isClearable={false}
            isSearchable={false}
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
            styles={{
              container: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
          />
        </div>
      )}

      {selectedPeriod && selectedPeriod.id === 'month' && (
        <div className="multi-selectbox">
          {fitlerLabel && <label className={filterLabel}>Month</label>}
          <ReactSelect
            options={selectMonth.map(({ id, label }: any) => ({
              label,
              value: id,
            }))}
            value={
              Object.keys(selectedMonth).length > 0
                ? { label: selectedMonth.label, value: selectedMonth.id }
                : null
            }
            placeholder="Select Month"
            isDisabled={false}
            onChange={(selectedOption) => {
              setSelectedDate(null);
              setSelectedMonth?.(
                selectedOption
                  ? { label: selectedOption.label, id: selectedOption.value }
                  : null
              );
            }}
            isClearable={false}
            isSearchable={false}
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
            styles={{
              container: (provided) => ({
                ...provided,
                zIndex: 999,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 999,
              }),
            }}
          />
        </div>
      )}

      {selectedPeriod && selectedPeriod.id === 'week' && (
        <div className="week-dropdown">
          {fitlerLabel && <label className={filterLabel}>Week</label>}
          <ReactSelect
            options={weeksOptions.map(({ id, label }: any) => ({
              label,
              value: id,
            }))}
            value={
              Object.keys(selectedWeeks).length > 0
                ? { label: selectedWeeks.label, value: selectedWeeks.id }
                : null
            }
            placeholder="Select Week"
            isDisabled={false}
            onChange={(selectedOption) => {
              setSelectedDate(null);
              setSelectedWeeks?.(
                selectedOption
                  ? { label: selectedOption.label, id: selectedOption.value }
                  : null
              );
            }}
            isClearable={true}
            isSearchable={true}
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
            styles={{
              container: (provided) => ({
                ...provided,
                zIndex: 99,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 99,
              }),
              control: (provided) => ({
                ...provided,
                minWidth: '350px',
                maxWidth: '400px',
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }),
            }}
          />
        </div>
      )}
      {selectedPeriod && shouldShowButtons && (
        <div
          style={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {/* Date Picker */}
          <DatePicker
            value={selectedDate}
            onChange={({ date }: any) => handleDateChange(date as Date)}
            minDate={new Date(minYear, 0, 1)}
            maxDate={new Date(maxYear, 11, 31)}
            placeholder="Calendar"
            size={SIZE.compact}
            formatString="yyyy-MM-dd"
            overrides={{
              Popover: {
                props: {
                  overrides: {
                    Body: {
                      style: { zIndex: 9 },
                    },
                  },
                },
              },
              Input: {
                props: {
                  overrides: {
                    Input: {
                      style: () => ({
                        border: '2px solid #fff',
                        borderRadius: '0.5rem',
                        backgroundColor: '#fff',
                        paddingRight: '30px',
                      }),
                    },
                    InputContainer: {
                      style: () => ({
                        border: '2px solid #fff',
                        borderRadius: '0.5rem',
                        backgroundColor: '#fff',
                      }),
                    },
                  },
                },
              },
            }}
          />

          {/* Custom SVG Calendar Icon */}
          <span
            style={{
              position: 'absolute',
              right: '8px',
              pointerEvents: 'none',
              color: '#666',
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="3" y="4" width="18" height="18" rx="2" fill="#666" />
              <path d="M3 8H21" stroke="white" strokeWidth="1.5" />
              <path d="M8 3V6" stroke="white" strokeWidth="1.5" />
              <path d="M16 3V6" stroke="white" strokeWidth="1.5" />
            </svg>
          </span>
        </div>
      )}
      {/* Wrap Refresh and Info Icon together */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {/* Refresh button */}
        {shouldShowButtons && (
          <div className={buttonContainerStyle} ref={dropdownRef}>
            <div className={buttonStyle} onClick={() => applyFilter(false)}>
              <p style={{ margin: 0 }}>Refresh</p>
              <button
                className={toggleStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleClick();
                }}
              >
                <span className="caret-icon">▼</span>
              </button>
            </div>
            {/* Dropdown for secondary button */}
            {isDropdownVisible && (
              <div className={dropdownStyle}>
                <div
                  className={dropdownItemStyle}
                  onClick={() => {
                    applyFilter(true);
                    setIsDropdownVisible(false);
                  }}
                >
                  Refresh From ERP
                </div>
              </div>
            )}
          </div>
        )}

        {/* Information Icon */}
        {shouldShowIcon && (salesLastFetchedAt || cogsLastFetchedAt) && (
          <span
            style={{
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              marginLeft: '4px',
            }}
          >
            <span
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '24px',
                height: '24px',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                const tooltip: any = e.currentTarget.nextElementSibling;
                if (tooltip) {
                  tooltip.style.visibility = 'visible';
                  tooltip.style.opacity = '1';
                  tooltip.style.transform = 'translateY(0)';
                }
              }}
              onMouseLeave={(e) => {
                const tooltip: any = e.currentTarget.nextElementSibling;
                if (tooltip) {
                  tooltip.style.visibility = 'hidden';
                  tooltip.style.opacity = '0';
                  tooltip.style.transform = 'translateY(-5px)';
                }
              }}
            >
              <img
                src={informationIcon}
                alt="Info icon"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                }}
              />
            </span>
            {/* Tooltip */}
            <span
              style={{
                position: 'absolute',
                bottom: '120%',
                width: '200px',
                left: '50%',
                transform: 'translateX(-50%) translateY(-5px)',
                backgroundColor: '#333',
                color: '#fff',
                padding: '8px 10px',
                borderRadius: '4px',
                whiteSpace: 'pre-line',
                visibility: 'hidden',
                opacity: 0,
                transition: 'opacity 0.3s ease, transform 0.3s ease',
                fontSize: '12px',
                fontStyle: 'italic',
                zIndex: '10000',
              }}
              className="tooltip-content"
            >
              {`Sales last fetched at:\n ${salesLastFetchedAt}\n\nCogs last fetched at:\n ${cogsLastFetchedAt}`}
              {/* Tooltip arrow */}
              <span
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '5px solid transparent',
                  borderRight: '5px solid transparent',
                  borderTop: '5px solid #333',
                }}
              ></span>
            </span>
          </span>
        )}
      </div>
    </>
  );
};

export default AllFilters;
