import React from 'react';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'styletron-react';
import moment from 'moment';

interface IProps {
  store: any;
  startDate: Array<any>;
  endDate: Array<any>;
  dateOption: any;
  dateFilter: any;
}

const PrintFilter = ({
  store,
  startDate,
  endDate,
  dateOption,
  dateFilter,
}: IProps) => {
  const [css] = useStyletron();
  const [validForAllStores] = React.useState(true);

  return (
    <div className="create-order-list">
      <div>Invoice Report</div>
      <div>
        <FlexGrid
          flexGridColumnCount={[1, 2, 5]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Date Period</div>
              <label htmlFor="">
                {dateOption[0]?.label ? dateOption[0]?.label : 'All'}
              </label>
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Start Date</div>
              <label htmlFor="">
                {moment(startDate[0]).format('YYYY:MM:DD')}
              </label>
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">End Date</div>
              <label htmlFor="">
                {' '}
                {moment(endDate[0]).format('YYYY:MM:DD')}
              </label>
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Filter By</div>
              <label htmlFor="">
                {dateFilter[0]?.label ? dateFilter[0]?.label : 'Creation Date'}
              </label>
            </div>
          </FlexGridItem>
          <FlexGridItem
            className={css({
              width: 'fit-content !important',
              marginLeft: '5px',
            })}
          >
            <div className="input-wrapper">
              <div className="input-label" style={{ marginBottom: '12px' }}>
                Valid for all stores
              </div>
              <Checkbox
                disabled={true}
                checked={validForAllStores}
                checkmarkType={STYLE_TYPE.toggle_round}
                overrides={{
                  Toggle: {
                    style: ({ $theme, $checked }) => ({
                      backgroundColor: $checked ? '#09B08C' : '#000',
                    }),
                  },
                }}
              />
            </div>
          </FlexGridItem>
        </FlexGrid>
      </div>
    </div>
  );
};

export default PrintFilter;
