/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useStyletron } from 'styletron-react';
import AnalyticsFilters from './components/filters';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import ArrangeIcon from '../../assets/icons/up-down-arrow.svg';
import CollapsibleArrow from '../../assets/icons/table-row-collapsible.svg';
import { AppContext, DispatcherAction } from 'context';
import { Spinner } from 'baseui/spinner';

interface visibleTableBodyProps {
  [key: number]: boolean;
}

const Analytics = () => {
  const [css] = useStyletron();
  const { dispatcher } = useContext(AppContext);
  const [visibleTableBody, setVisibleTableBody] =
    useState<visibleTableBodyProps>({});
  const [tableData, setTableData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  }>({ key: 'sales.net_sales', direction: 'desc' });
  const [total, setTotal] = useState<any>({});
  const [initialSortDone, setInitialSortDone] = useState<boolean>(false);

  const BREADCRUMB_ITEMS = [
    { name: 'Analytics', route: '/analytics' },
    { name: 'Food Cost', route: '/foodcost' },
    { name: 'COGS Summary', route: '/analytics' },
  ];

  useLayoutEffect(() => {
    dispatcher(DispatcherAction.BreadCrumbs, BREADCRUMB_ITEMS);
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.target) {
          const id = parseInt(entry.target.getAttribute('data-id') || '0', 10);
          setVisibleTableBody((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        } else {
          console.error('Intersection without target:', entry);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    // Observe each nested thead
    document.querySelectorAll('.nested-data').forEach((elem) => {
      observer.observe(elem);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleCollapsibleBody = (id: number, status: any) => {
    setVisibleTableBody((prevState) => ({
      ...prevState,
      [id]: status ?? !prevState[id],
    }));
  };

  useEffect(() => {
    if (tableData) {
      for (let i = 0; i < tableData?.length; i++) {
        handleCollapsibleBody(i, true);
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (tableData.length > 0 && !initialSortDone) {
      handleSort('sales.net_sales', 'desc');
      setInitialSortDone(true);
    }
    // eslint-disable-next-line
  }, [tableData, initialSortDone]);

  const handleSort = (key: string, direction: 'asc' | 'desc' = 'asc') => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = sortData(tableData, key, direction);
    setTableData(sortedData);
  };

  const sortData = (data: any[], key: string, direction: 'asc' | 'desc') => {
    const sortedData = [...data].sort((a, b) => {
      const aValue = key.split('.').reduce((acc, part) => acc && acc[part], a);
      const bValue = key.split('.').reduce((acc, part) => acc && acc[part], b);

      const aNum = parseFloat(aValue.replace(/,/g, '').replace('%', ''));
      const bNum = parseFloat(bValue.replace(/,/g, '').replace('%', ''));

      if (aNum < bNum) return direction === 'asc' ? -1 : 1;
      if (aNum > bNum) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    sortedData.forEach((brand: any) => {
      if (brand.data && brand.data.length > 0) {
        brand.data = brand.data.sort((a: any, b: any) => {
          const aValue = key
            .split('.')
            .reduce((acc, part) => acc && acc[part], a);
          const bValue = key
            .split('.')
            .reduce((acc, part) => acc && acc[part], b);

          const aNum = parseFloat(aValue.replace(/,/g, '').replace('%', ''));
          const bNum = parseFloat(bValue.replace(/,/g, '').replace('%', ''));

          if (aNum < bNum) return direction === 'asc' ? -1 : 1;
          if (aNum > bNum) return direction === 'asc' ? 1 : -1;
          return 0;
        });
      }
    });

    return sortedData;
  };

  const mainWapper = css({
    padding: '16px',
  });
  const cellDarkGray = css({
    backgroundColor: '#e8e8ff',
  });
  const loader = css({
    height: 'calc(100vh - 380px)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  });
  const CellLightGray = css({
    backgroundColor: '#eff0ff',
  });
  const stickyColumn = css({
    position: 'sticky',
    left: 0,
    zIndex: 1,
    boxShadow: '0px 10px 20px 0px rgba(48, 51, 147, .10)',
  });
  const arrangeIconStyle = css({
    position: 'absolute',
    top: '50%',
    right: '4.5px',
    transform: 'translateY(-50%)',
  });
  const collapsibleArrowStyle = css({
    position: 'relative',
    top: '50%',
    transition: '0.3s all ease-in',
  });
  const storeTitle = css({
    paddingLeft: '8px',
    fontWeight: '500',
  });
  const pointerCursor = css({
    cursor: 'pointer',
  });
  return (
    <>
      <div className={`${mainWapper} font-family-poppins`}>
        <AnalyticsFilters
          setTableData={(data: any) => {
            setInitialSortDone(false);
            setTableData(data);
          }}
          setTotal={setTotal}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />

        <div className="cogs-table-wrapper">
          {tableData ? (
            <>
              <table className="cogs-table">
                <thead>
                  <tr>
                    <th rowSpan={2} className="first-col">
                      Stores
                    </th>
                    <th colSpan={3}>Sales</th>
                    <th colSpan={3}>Actual Food Cost</th>
                    <th colSpan={3}>Net COGS vs Budget</th>
                    <th colSpan={3}>Actual vs Theo</th>
                  </tr>
                  <tr>
                    <th
                      className={`${CellLightGray} ${pointerCursor}`}
                      onClick={() => handleSort('sales.g_sales')}
                    >
                      Gross Sales
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${CellLightGray} ${pointerCursor}`}
                      onClick={() => handleSort('sales.net_sales')}
                    >
                      Net Sales
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${CellLightGray} ${pointerCursor}`}
                      onClick={() => handleSort('sales.discount')}
                    >
                      Discount
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${cellDarkGray} ${pointerCursor}`}
                      onClick={() => handleSort('actual_food_cost.cost_ahead')}
                    >
                      Cost
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${cellDarkGray} ${pointerCursor}`}
                      onClick={() =>
                        handleSort('actual_food_cost.food_cost_g_sales')
                      }
                    >
                      Gross Cost %
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${cellDarkGray} ${pointerCursor}`}
                      onClick={() =>
                        handleSort('actual_food_cost.food_cost_net_sales')
                      }
                    >
                      Net Cost %
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${CellLightGray} ${pointerCursor}`}
                      onClick={() => handleSort('net_cogs_budget.budget')}
                    >
                      Budget
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${CellLightGray} ${pointerCursor}`}
                      onClick={() => handleSort('net_cogs_budget.variance')}
                    >
                      Variance
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${CellLightGray} ${pointerCursor}`}
                      onClick={() => handleSort('net_cogs_budget.aed')}
                    >
                      Variance in AED
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${cellDarkGray} ${pointerCursor}`}
                      onClick={() => handleSort('actual_theo.theo')}
                    >
                      Theo
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${cellDarkGray} ${pointerCursor}`}
                      onClick={() => handleSort('actual_theo.variance1')}
                    >
                      Variance
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                    <th
                      className={`${cellDarkGray} ${pointerCursor}`}
                      onClick={() => handleSort('actual_theo.variance2')}
                    >
                      Variance in AED
                      <img
                        src={ArrangeIcon}
                        alt=""
                        className={arrangeIconStyle}
                        width="10"
                        height="10"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={13} className="tdreset">
                        <div className={loader}>
                          <Spinner $size="50" />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    tableData &&
                    tableData?.map((item: any, id: any) => (
                      <React.Fragment key={id}>
                        <tr
                          className="main-row"
                          onClick={() => handleCollapsibleBody(id, null)}
                        >
                          <td>
                            <img
                              src={CollapsibleArrow}
                              alt=""
                              className={collapsibleArrowStyle}
                              style={{
                                transform: visibleTableBody[id]
                                  ? 'rotate(0deg)'
                                  : 'rotate(180deg)',
                              }}
                            />
                            <span
                              className={storeTitle}
                              style={{ fontWeight: '500' }}
                            >
                              {item.name}
                            </span>
                          </td>
                          <td className="number-cell">{item.sales.g_sales}</td>
                          <td className="number-cell">
                            {item.sales.net_sales}
                          </td>
                          <td className="percent-column number-cell">
                            {item.sales.discount}
                          </td>
                          <td className="number-cell">
                            {item.actual_food_cost.cost_ahead}
                          </td>
                          <td
                            className="percent-column number-cell"
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.actual_food_cost.food_cost_g_sales}
                          </td>
                          <td
                            className="percent-column number-cell"
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.actual_food_cost.food_cost_net_sales}
                          </td>
                          <td
                            className="percent-column number-cell"
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.net_cogs_budget.budget}
                          </td>
                          <td
                            className="percent-column number-cell"
                            style={{
                              color:
                                item.net_cogs_budget.variance.split('%')[0] > 0
                                  ? 'green'
                                  : 'red',
                              fontWeight: 'bold',
                            }}
                          >
                            {item.net_cogs_budget.variance}
                          </td>
                          <td className="number-cell">
                            {item.net_cogs_budget.aed}
                          </td>
                          <td
                            className="percent-column number-cell"
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.actual_theo.theo}
                          </td>
                          <td
                            className="percent-column number-cell"
                            style={{
                              color:
                                item.actual_theo.variance1.split('%')[0] > 0
                                  ? 'green'
                                  : 'red',
                              fontWeight: 'bold',
                            }}
                          >
                            {item.actual_theo.variance1}
                          </td>
                          <td className="percent-column number-cell">
                            {item.actual_theo.variance2}
                          </td>
                        </tr>
                        {visibleTableBody[id] && (
                          <tr className="sub-table">
                            <td colSpan={13}>
                              <table className="nested-table">
                                <tbody>
                                  {item.data.map(
                                    (storeData: any, index: any) => (
                                      <tr key={`storeData-${index}`}>
                                        <td
                                          style={{
                                            width: '150px',
                                            position: 'relative', // Ensure positioning context for the tooltip
                                            overflow: 'visible', // Prevent clipping of the tooltip
                                          }}
                                          onMouseEnter={(e) => {
                                            const tooltip: HTMLElement | null =
                                              e.currentTarget.querySelector(
                                                '.tooltip-content'
                                              );
                                            if (tooltip) {
                                              tooltip.style.visibility =
                                                'visible';
                                              tooltip.style.opacity = '1';
                                            }
                                          }}
                                          onMouseLeave={(e) => {
                                            const tooltip: HTMLElement | null =
                                              e.currentTarget.querySelector(
                                                '.tooltip-content'
                                              );
                                            if (tooltip) {
                                              tooltip.style.visibility =
                                                'hidden';
                                              tooltip.style.opacity = '0';
                                            }
                                          }}
                                        >
                                          {storeData.name}

                                          {/* Tooltip that shows on hover */}
                                          <span
                                            style={{
                                              position: 'absolute',
                                              top: '120%', // Adjust the positioning
                                              left: '50%', // Center horizontally relative to the cell
                                              transform: 'translateX(-50%)', // Center the tooltip horizontally
                                              backgroundColor: '#000',
                                              color: '#fff',
                                              padding: '5px',
                                              borderRadius: '4px',
                                              whiteSpace: 'pre-line', // Allow line breaks
                                              visibility: 'hidden',
                                              opacity: 0,
                                              transition:
                                                'opacity 0.3s ease, visibility 0.3s ease', // Smooth transition for both opacity and visibility
                                              fontSize: '10px',
                                              width: '150px',
                                              zIndex: 1000, // Ensure tooltip is above other elements
                                              fontStyle: 'italic',
                                              textAlign: 'center',
                                              pointerEvents: 'none', // Prevent the tooltip from capturing hover events
                                            }}
                                            className="tooltip-content"
                                          >
                                            {`Sales last fetched at:\n ${
                                              storeData.lastSalesFetchedDate ?? 'N/A' // Replace with actual data
                                            }\nCogs last fetched at:\n ${
                                              storeData.lastCogsFetchedDate ??'N/A' // Replace with actual data
                                            }`}
                                          </span>
                                        </td>

                                        <td className="number-cell">
                                          {storeData.sales.g_sales}
                                        </td>
                                        <td className="number-cell">
                                          {storeData.sales.net_sales}
                                        </td>
                                        <td className="number-cell">
                                          {storeData.sales.discount}
                                        </td>
                                        <td className="number-cell">
                                          {
                                            storeData.actual_food_cost
                                              .cost_ahead
                                          }
                                        </td>
                                        <td
                                          className="number-cell"
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          {
                                            storeData.actual_food_cost
                                              .food_cost_g_sales
                                          }
                                        </td>
                                        <td
                                          className="number-cell"
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          {
                                            storeData.actual_food_cost
                                              .food_cost_net_sales
                                          }
                                        </td>
                                        <td
                                          className="number-cell"
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          {storeData.net_cogs_budget.budget}
                                        </td>
                                        <td
                                          className="percent-column number-cell"
                                          style={{
                                            color:
                                              storeData.net_cogs_budget.variance.split(
                                                '%'
                                              )[0] > 0
                                                ? 'green'
                                                : 'red',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {storeData.net_cogs_budget.variance}
                                        </td>
                                        <td className="number-cell">
                                          {storeData.net_cogs_budget.aed}
                                        </td>
                                        <td
                                          className="percent-column number-cell"
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          {storeData.actual_theo.theo}
                                        </td>
                                        <td
                                          className="percent-column number-cell"
                                          style={{
                                            color:
                                              storeData.actual_theo.variance1.split(
                                                '%'
                                              )[0] > 0
                                                ? 'green'
                                                : 'red',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {storeData.actual_theo.variance1}
                                        </td>
                                        <td className="number-cell">
                                          {storeData.actual_theo.variance2}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
              {total && Object.keys(total).length > 0 && (
                <table
                  className="cogs-table total-table"
                  style={{ marginTop: '30px' }}
                >
                  <tbody>
                    <tr className="active">
                      <td className={`${stickyColumn} first-col`}>Total</td>
                      <td className="number-cell">{total?.sales.g_sales}</td>
                      <td className="number-cell">{total?.sales.net_sales}</td>
                      <td className="number-cell">{total?.sales.discount}</td>
                      <td className="number-cell">
                        {total?.actual_food_cost.cost_ahead}
                      </td>
                      <td
                        style={{ fontWeight: 'bold' }}
                        className="number-cell"
                      >
                        {total?.actual_food_cost.food_cost_g_sales}
                      </td>
                      <td
                        style={{ fontWeight: 'bold' }}
                        className="number-cell"
                      >
                        {total?.actual_food_cost.food_cost_net_sales}
                      </td>
                      <td
                        style={{ fontWeight: 'bold' }}
                        className="number-cell"
                      >
                        {total?.net_cogs_budget.budget}
                      </td>
                      <td
                        style={{ fontWeight: 'bold' }}
                        className="number-cell"
                      >
                        {total?.net_cogs_budget.variance}
                      </td>
                      <td className="number-cell">
                        {total?.net_cogs_budget.aed}
                      </td>
                      <td style={{ fontWeight: 'bold' }}>
                        {total?.actual_theo.theo}
                      </td>
                      <td
                        style={{ fontWeight: 'bold' }}
                        className="number-cell"
                      >
                        {total?.actual_theo.variance1}
                      </td>
                      <td className="number-cell">
                        {total?.actual_theo.variance2}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </>
          ) : (
            'No Data Found'
          )}
        </div>
      </div>
    </>
  );
};

export default Analytics;
