export const dates = [
  {
    id: 'all',
    label: 'All',
    secondDates: [],
  },
  {
    id: 'custom',
    label: 'Custom',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'previous_week',
        label: 'Prev. Week',
      },
      {
        id: 'previous_month',
        label: 'Prev. Month',
      },
      {
        id: 'previous_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'today',
    label: 'Today',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'yesterday',
        label: 'Yesterday',
      },
      {
        id: 'previous_today_week',
        label: 'Prev. Week',
      },
      {
        id: 'last_month',
        label: 'Prev. Month',
      },
      {
        id: 'last_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'previous_yesterday_week',
        label: 'Prev. Week',
      },
      {
        id: 'last_month',
        label: 'Prev Month',
      },
      {
        id: 'last_year',
        label: 'Prev Year',
      },
    ],
  },
  {
    id: 'this_week',
    label: 'This Week',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'last_week',
        label: 'Prev. Week',
      },
      {
        id: 'last_month',
        label: 'Prev. Month',
      },
      {
        id: 'last_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'last_week',
    label: 'Last Week',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'previous_week',
        label: 'Prev. Week',
      },
      {
        id: 'last_month',
        label: 'Prev. Month',
      },
      {
        id: 'last_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'this_month',
    label: 'This Month',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'last_month',
        label: 'Prev. Month',
      },
      {
        id: 'last_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'last_month',
    label: 'Last Month',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'previous_month',
        label: 'Prev. Month',
      },
      {
        id: 'last_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'this_year',
    label: 'This Year',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'last_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'last_year',
    label: 'Last Year',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'previous_year',
        label: 'Prev. Year',
      },
    ],
  },
  {
    id: 'inventory_week',
    label: 'Inventory Week',
    secondDates: [
      {
        id: 'custom',
        label: 'Custom',
      },
      {
        id: 'previous_week',
        label: 'Prev. Week',
      },
      {
        id: 'previous_year',
        label: 'Prev. Year',
      },
    ],
  },
];

export const transferListDateFilters: any = [
  {
    label: 'Creation Date',
    id: 'CreatedAt',
  },
  {
    label: 'Archived Date',
    id: 'ArchivedDate',
  },
  {
    label: 'Published Date',
    id: 'PublishedAt',
  },
];

export const dateFilters: any = [
  {
    label: 'Creation Date',
    id: 'createdAt',
  },
  {
    label: 'Released Date',
    id: 'releasedAt',
  },
  {
    label: 'Supply Date',
    id: 'SupplyDate',
  },
];
export const userRole: any = [
  {
    label: 'SuperAdmin',
    id: 'SuperAdmin',
  },
  {
    label: 'Admin',
    id: 'Admin',
  },
  {
    label: 'User',
    id: 'User',
  },
  {
    label: 'Purchase Support',
    id: 'PurchaseSupport',
  },
  {
    label: 'Management ReadOnly',
    id: 'ManagementReadOnly',
  },
];

export const voiceFilter: any = [
  {
    label: 'Invoice Date',
    id: 'invoiceDate',
    keys: ['0', '1', '2', '3', '4'],
  },
  {
    label: 'Creation Date',
    id: 'createdAt',
    keys: ['0', '1', '2', '3', '4'],
  },
  {
    label: 'Finalize Date',
    id: 'finalizedAt',
    keys: ['1', '2', '3'],
  },
  {
    label: 'Posting Date',
    id: 'postedAt',
    keys: ['2', '3'],
  },
  {
    label: 'Archived Date',
    id: 'archivedAt',
    keys: ['4'],
  },
];

export const conflictIvoiceFilter: any = [
  {
    label: 'Invoice Date',
    id: 'receivingDate',
    keys: ['Conflict', 'Review', 'Resolved', 'Cancelled', 'All'],
  },
  {
    label: 'Creation Date',
    id: 'createdAt',
    keys: ['Conflict', 'Review', 'Resolved', 'Cancelled', 'All'],
  },
];

export const conflictIvoiceSort: any = [
  {
    label: 'Doc No.',
    id: 'doc_no',
  },
  {
    label: 'Invoice Date',
    id: 'receivingDate',
  },
  {
    label: 'Creation Date',
    id: 'createdAt',
  },
  {
    label: 'Order No',
    id: 'order_no',
  },
  {
    label: 'Invoice Amount',
    id: 'invoiceAmount',
  },
];

export const InvoiceSort: any = [
  {
    label: 'Doc No.',
    id: 'doc_no',
  },
  {
    label: 'Invoice Date',
    id: 'invoiceDate',
  },
  {
    label: 'Creation Date',
    id: 'createdAt',
  },
  {
    label: 'Order No',
    id: 'order_no',
  },
  {
    label: 'Net Amount',
    id: 'amountWoVAT',
  },
];

export const requisitionFilter: any = [
  {
    label: 'Creation Date',
    id: 'createdAt',
    keys: ['Draft', 'Submitted', 'Processed', 'Archived', 'All'],
  },
  {
    label: 'Supply Date',
    id: 'SupplyDate',
    keys: ['Draft', 'Submitted', 'Processed', 'Archived', 'All'],
  },
  {
    label: 'Submit Date',
    id: 'submitDate',
    keys: ['Submitted', 'Processed', 'All'],
  },
  {
    label: 'Process Date',
    id: 'processedDate',
    keys: ['Processed', 'All'],
  },
  {
    label: 'Archive Date',
    id: 'archivedDate',
    keys: ['Archived', 'All'],
  },
];

export const typeFilterOptions: any = [
  {
    label: 'Manual',
    id: 'Manual',
  },
  {
    label: 'Ordered',
    id: 'Ordered',
  },
  {
    label: 'All',
    id: 'All',
  },
];

export const pageSizeOption = [
  { label: 10, id: '1' },
  { label: 20, id: '2' },
  { label: 50, id: '3' },
  { label: 100, id: '4' },
];

export const sortBy = [
  { label: 'Code', id: 'num' },
  { label: 'Name', id: 'name' },
];

export const sortByOrderReleased = [
  { label: 'Order No', id: 'orderNumber' },
  { label: 'Order Value', id: 'value' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Release Date', id: 'releasedAt' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req No', id: 'reqNumber' },
];

export const sortByOrderCompleted = [
  { label: 'Order No', id: 'orderNumber' },
  { label: 'Order Value', id: 'value' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Received Date', id: 'receivedAt' },
];

export const sortByOrderPartial = [
  { label: 'Order No', id: 'orderNumber' },
  { label: 'Order Value', id: 'value' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Released Date', id: 'releasedAt' },
  { label: 'Received Date', id: 'receivedAt' },
];

export const sortByOrderConflict = [
  { label: 'Order No', id: 'orderNumber' },
  { label: 'Order Value', id: 'value' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Released Date', id: 'releasedAt' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req No', id: 'reqNumber' },
];

export const sortByOrderAll = [
  { label: 'Order No', id: 'orderNumber' },
  { label: 'Order Value', id: 'value' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Released Date', id: 'releasedAt' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req No', id: 'reqNumber' },
];

export const sortByOrderDraft = [
  { label: 'Order No', id: 'orderNumber' },
  { label: 'Order Value', id: 'value' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req No', id: 'reqNumber' },
];

export const sortByOrderCancelled = [
  { label: 'Order No', id: 'orderNumber' },
  { label: 'Order Value', id: 'value' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Cancelled Date', id: 'receivedAt' },
  { label: 'Req No', id: 'reqNumber' },
];

export const sortByAll = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Created Date', id: 'createdAt' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req Code', id: 'req_code' },
];

export const sortBySubmit = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Submit Date', id: 'submitDate' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req Code', id: 'req_code' },
];

export const sortByProcess = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Process Date', id: 'processedDate' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req Code', id: 'req_code' },
];

export const sortByArchive = [
  { label: 'Total Amount', id: 'totalAmount' },
  { label: 'Archive Date', id: 'archivedDate' },
  { label: 'Supply Date', id: 'SupplyDate' },
  { label: 'Req Code', id: 'req_code' },
];

export const sortByTypes = [
  { label: 'Asc', id: 'asc' },
  { label: 'Desc', id: 'desc' },
];

export const tablist = ['Active', 'Inactive', 'All Suppliers'];

export const selectPeriod = [
  // { label: 'Yearly', id: 'year' },
  { label: 'Monthly', id: 'month' },
  { label: 'Weekly', id: 'week' },
];

export const grossSaleFormula = [
  { label: 'Gross Minus Expected VAT', id: 'gross_minus_vat' },
  { label: 'Gross Minus Actual VAT', id: 'gross_bf_discount_minus_vat' },
  { label: 'Gross including VAT', id: 'gross_bf_discount_vat' },
];

export const selectYear = [
  { label: '2020', id: '2020' },
  { label: '2021', id: '2021' },
  { label: '2022', id: '2022' },
  { label: '2023', id: '2023' },
  { label: '2024', id: '2024' },
  { label: '2025', id: '2025' },
  { label: '2026', id: '2026' },
];

export const selectTrend = [
  { label: '1 Year', id: '1year' },
  { label: '2 Years', id: '2years' },
  { label: '3 Years', id: '3years' },
  { label: '4 Years', id: '4years' },
  { label: '5 Years', id: '5years' },
  { label: '6 Years', id: '6years' },
];

export const selectMonth = [
  { label: 'January', id: '1' },
  { label: 'February', id: '2' },
  { label: 'March', id: '3' },
  { label: 'April', id: '4' },
  { label: 'May', id: '5' },
  { label: 'June', id: '6' },
  { label: 'July', id: '7' },
  { label: 'August', id: '8' },
  { label: 'September', id: '9' },
  { label: 'October', id: '10' },
  { label: 'November', id: '11' },
  { label: 'December', id: '12' },
];

export const selectMonthTrend = [
  { label: '1 Month', id: '1month' },
  { label: '2 Months', id: '2months' },
  { label: '3 Months', id: '3months' },
  { label: '4 Months', id: '4months' },
  { label: '5 Months', id: '5months' },
  { label: '6 Months', id: '6months' },
  { label: '7 Months', id: '7months' },
  { label: '8 Months', id: '9months' },
  { label: '10 Months', id: '10months' },
  { label: '11 Months', id: '11months' },
  { label: '12 Months', id: '12months' },
];

export const selectWeeks = [
  { label: '1 Week', id: '1' },
  { label: '2 Weeks', id: '2' },
  { label: '3 Weeks', id: '3' },
  { label: '4 Weeks', id: '4' },
];

export const selectWeeksTrend = [
  { label: '1 Week', id: '1week' },
  { label: '2 Weeks', id: '2weeks' },
  { label: '3 Weeks', id: '3weeks' },
  { label: '4 Weeks', id: '4weeks' },
  { label: '5 Weeks', id: '5weeks' },
  { label: '6 Weeks', id: '6weeks' },
  { label: '7 Weeks', id: '7weeks' },
];

export const selectRegion = [
  { label: 'UAE', id: '1', selected: false },
  { label: 'KSA', id: '2', selected: false },
  { label: 'QAR', id: '3', selected: false },
];

export const selectBrand = [
  { label: 'TDB', id: 'TDB' },
  { label: 'YO', id: 'YO' },
  { label: 'CPK', id: 'CPK' },
  { label: 'HBIRD', id: 'HBIRD' },
  { label: 'PANDA', id: 'PANDA' },
  { label: 'AZK', id: 'AZK' },
];

export const selectMall = [
  { label: 'Mall 1', id: 'mall1' },
  { label: 'Mall 2', id: 'Mall 2' },
  { label: 'Mall 3', id: 'Mall 3' },
];

export const selectStore = [
  // { label: 'Select All', id: 'Select All' },
  { label: 'CPKMOE', id: 'CPKMOE' },
  { label: 'CPKDFC', id: 'CPKDFC' },
  { label: 'MPKMOE', id: 'MPKMOE' },
  { label: 'PXMICC', id: 'PXMICC' },
  { label: 'TDBMOE', id: 'TDBMOE' },
  { label: 'TDBYAS', id: 'TDBYAS' },
  { label: 'TDBDM', id: 'TDBDM' },
];

export const sortTransferListDefault = [
  { label: 'Created At', id: 'createdAt' },
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Status', id: 'status' },
  { label: 'Transfer Code', id: 'transferListCode' },
];

export const sortTransferListPublished = [
  { label: 'Publish At', id: 'publishedAt' },
  { label: 'Created At', id: 'createdAt' },
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Status', id: 'status' },
  { label: 'Transfer Code', id: 'transferListCode' },
];

export const sortTransferListArchived = [
  { label: 'Archive At', id: 'archivedAt' },
  { label: 'Created At', id: 'createdAt' },
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Status', id: 'status' },
  { label: 'Transfer Code', id: 'transferListCode' },
];

export const sortTransferRequestDefault = [
  { label: 'Created At', id: 'createdAt' },
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Status', id: 'status' },
  { label: 'Request #', id: 'transferCode' },
];

export const sortTransferRequestSubmit = [
  { label: 'Submitted At', id: 'submittedAt' },
  { label: 'Created At', id: 'createdAt' },
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Status', id: 'status' },
  { label: 'Request #', id: 'transferCode' },
];

export const sortTransferRequestPartial = [
  { label: 'Created At', id: 'createdAt' },
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Status', id: 'status' },
  { label: 'Request #', id: 'transferCode' },
];

export const sortTransferRequestComplete = [
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Created At', id: 'createdAt' },
  { label: 'Status', id: 'status' },
  { label: 'Request #', id: 'transferCode' },
];

export const sortTransferRequestDecline = [
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Created At', id: 'createdAt' },
  { label: 'Status', id: 'status' },
  { label: 'Request #', id: 'transferCode' },
];

export const sortTransferOrderDefault = [
  { label: 'Supply At', id: 'supplyAt' },
  { label: 'Order #', id: 'transferOrderCode' },
  { label: 'Status', id: 'status' },
  { label: 'Created At', id: 'createdAt' },
];

export const sortTransferOrderShipped = [
  { label: 'Supply At', id: 'supplyAt' },
  { label: 'Shipped At', id: 'shippedAt' },
  { label: 'Order #', id: 'transferOrderCode' },
  { label: 'Status', id: 'status' },
  { label: 'Created At', id: 'createdAt' },
];

export const sortTransferOrderConflict = [
  { label: 'Receive At', id: 'receivedAt' },
  { label: 'Supply At', id: 'supplyAt' },
  { label: 'Order #', id: 'transferOrderCode' },
  { label: 'Status', id: 'status' },
  { label: 'Created At', id: 'createdAt' },
];

export const sortTransferOrderCompleted = [
  { label: 'Updated At', id: 'updatedAt' },
  { label: 'Receive At', id: 'receivedAt' },
  { label: 'Supply At', id: 'supplyAt' },
  { label: 'Order #', id: 'transferOrderCode' },
  { label: 'Status', id: 'status' },
  { label: 'Created At', id: 'createdAt' },
];
