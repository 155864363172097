/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import SidebarCollapsibleAngle from 'assets/icons/sidebar-collapible-angle.svg';
import SidebarCollapsibleHumberger from 'assets/icons/sidebar-collisible-humberger.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { AppContext } from 'context';
import colors from 'constants/colors';
import SideBarLink from './sidebar-link';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { Button, KIND } from 'baseui/button';
import moment from 'moment';
import { urls } from 'constants/urls';
import { SidebarProps } from 'modules/shared/interfaces/sidebar.interface';
import { ANALYTICS_NAVIGATION_LINKS, NAVIGATION_LINKS } from './sidebarData';
import './sidebar.scss';
import { PermissionsNavigation } from './transfersNavigations';
import { Axios } from 'core/services/http';
import { UserRole } from 'modules/shared/enums/user-role.enum';

const SideBar = ({ setIsSidebarOpen, isSidebarOpen }: SidebarProps) => {
  const [css] = useStyletron();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const axios = new Axios().getInstance();
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [isSubMenuOpen, setSubMenuIsOpen] = useState(false);
  const [foundRoute, setFoundRoute] = useState<any>();
  const [currentVersion] = useState<any>();
  const [isVersionDetailModalOpen, setIsVersionDetailModalOpen] =
    useState(false);

  const location = useLocation();
  const [navigationLinks, setNavigationLinks] =
    React.useState<Array<any>>(NAVIGATION_LINKS);
  // ===========Updated code===========
  const navigateToRoute = (id: number) => {
    const found = navigationLinks.find((link) => id === link.id);
    // the problem is when we click on a submenu it will not navigate to that route and isActive but when directly click on submenu it is not active
    if (found && found.subMenus && found.subMenus.length) {
      found.openSubMenus = !found.openSubMenus;
      found.isActive = true;
      found.isOpen = true;
      setNavigationLinks((prevNavigationLinks) => {
        const updatedLinks = prevNavigationLinks.map((link) => {
          if (link.id === found.id) {
            return found;
          }
          link.isActive = false;
          return link;
        });
        return updatedLinks;
      });
    } else {
      const tempList = [...navigationLinks];
      tempList.forEach((item) => {
        item.isActive = item.id === id;
      });
      setNavigationLinks(tempList);
    }
    if (localStorage.getItem('unsaved')) {
      setFoundRoute(found.route);
      setWarningPopUp(true);
    }
    if (!warningPopUp && !localStorage.getItem('unsaved')) {
      if (!found.route && found.hasSubMenus) {
        if (location.pathname && location.search) {
          navigate(`${location.pathname}${location.search}`);
          setFoundRoute(found.route);
        }
      } else {
        navigate(found.route);
      }
    }
  };

  useLayoutEffect(() => {
    checkModularAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  const checkModularAccess = async () => {
    try {
      const data = await axios.get('/users/get-module-access');
      if (!!data?.data?.data?.hasAccessToTransferModule) {
        setNavigationLinks((prevData) => PermissionsNavigation);
      } else {
        setNavigationLinks(
          urls?.includes(location?.pathname)
            ? ANALYTICS_NAVIGATION_LINKS
            : NAVIGATION_LINKS
        );
      }
    } catch (error) {
      setNavigationLinks(
        urls?.includes(location?.pathname)
          ? ANALYTICS_NAVIGATION_LINKS
          : NAVIGATION_LINKS
      );
    }
  };
  useEffect(() => {
    if (localStorage.getItem('unsaved') === 'true') {
      setWarningPopUp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('unsaved')]);

  const labelStyles = css({
    fontFamily: 'poppins-regular',
    fontSize: '16px',
    color: colors.darkBlue,
  });

  const textStyles = css({
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    color: colors.darkBlue,
  });
  const customSidebar = css({
    position: 'fixed',
    width: isSidebarOpen ? '220px' : '72px',
    top: '0px',
    left: '0px',
    bottom: '0px',
    overflow: 'hidden',
    transition: 'all 600ms cubic-bezier(0.07, 0.88, 0.37, 1.05)',
    zIndex: '99',
  });

  const sidebarBrand = css({
    fontSize: '20px',
    lineHeight: '30px',
    fontFamily: 'poppins-medium',
    padding: isSidebarOpen ? '29.6px 24px' : '29.6px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const sidebarHumberger = css({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
  const humberger = css({
    position: 'absolute',
    width: '26px',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '-26px',
    background: '#0d1b2a',
    color: '#fff',
    display: 'flex',
    padding: '8px 0',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  });
  const sidebarDropdown = css({
    opacity: isSidebarOpen ? '1' : '0',
    transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-20px)',
    transition: 'all 400ms ease',
  });
  console.log(state.user, 'sss');

  const restrictedRoutes = [
    '/master',
    '/setup',
    '/price-contracts',
    '/templates',
  ];

  const filteredNavigationLinks = navigationLinks.filter(({ route, name }) => {
    const isSuperAdmin = state?.user?.role === UserRole.SuperAdmin;
    const isAdmin = state?.user?.role === UserRole.Admin;
    const hasAccessToTransferModule =
      state?.user?.moduleAccess?.hasAccessToTransferModule;

    if (isSuperAdmin) {
      return true;
    }
    if (name === 'Transfers') {
      return !!hasAccessToTransferModule;
    }
    if (isAdmin) {
      if (route === '/setup') {
        return false;
      }
      return true;
    }

    return !restrictedRoutes.includes(route);
  });

  return (
    <>
      {urls?.includes(location?.pathname) ? (
        <>
          <div
            className={`${customSidebar} custom-sidebar font-family-poppins`}
          >
            <div className={humberger}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>

            <div className={sidebarBrand}>
              {isSidebarOpen && <div>Analytics</div>}

              <div>
                <button
                  type="button"
                  className={sidebarHumberger}
                  onClick={() => {
                    if (setIsSidebarOpen) {
                      setIsSidebarOpen(!isSidebarOpen);
                    }
                  }}
                >
                  <img
                    src={
                      isSidebarOpen
                        ? SidebarCollapsibleAngle
                        : SidebarCollapsibleHumberger
                    }
                    alt=""
                  />
                </button>
              </div>
            </div>

            <div className="menu">
              {filteredNavigationLinks && (
                <ul className="sidebar-memu-list">
                  {filteredNavigationLinks.map(
                    ({
                      id,
                      subMenus,
                      openSubMenus,
                      hasSubMenus,
                      isActive,
                      ...rest
                    }) => {
                      return (
                        <li
                          key={id}
                          className={`${isActive ? 'sidbar-link-active' : ''}
                            ${
                              subMenus && subMenus?.length > 0 && isSidebarOpen
                                ? 'sidbar-link-dropdown'
                                : ''
                            }
                          `}
                        >
                          <SideBarLink
                            {...rest}
                            isSubmenuOpen={true}
                            clicked={() => navigateToRoute(id)}
                            hasSubMenus={hasSubMenus}
                            openSubMenus={openSubMenus}
                            isSidebarOpen={isSidebarOpen}
                          />
                          {subMenus?.length > 0 && (
                            <ul className={sidebarDropdown}>
                              {subMenus.map((subMenu: any, index: any) => {
                                return (
                                  <li
                                    key={index}
                                    style={{
                                      paddingLeft: isSidebarOpen ? '10px' : '0',
                                      backgroundColor: 'transparent',
                                    }}
                                    className={
                                      location?.pathname === subMenu.route
                                        ? 'sidbar-link-active'
                                        : ''
                                    }
                                  >
                                    {openSubMenus && (
                                      <SideBarLink
                                        {...rest}
                                        name={subMenu.name}
                                        route={subMenu.route}
                                        icon={subMenu.icon}
                                        iconActive={subMenu.icon}
                                        isSidebarOpen={isSidebarOpen}
                                      />
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="menu">
          {' '}
          <div
            className="sidebarRootWrapper"
            style={{
              width: isSidebarOpen ? '220px' : '100px',
              paddingTop: !isSidebarOpen ? '75px' : '85px',
            }}
          >
            <div
              className="navigation-links-wrapper"
              style={{ paddingBottom: !isSidebarOpen ? '100px' : '16px' }}
            >
              {filteredNavigationLinks.map(
                ({ id, subMenus, openSubMenus, hasSubMenus, ...rest }) => {
                  // Conditional rendering based on state.user.hasAccessToTransferModule
                  const shouldRender =
                    id !== 4 ||
                    state?.user?.moduleAccess?.hasAccessToTransferModule;
                  return (
                    shouldRender && (
                      <React.Fragment key={id}>
                        <SideBarLink
                          {...rest}
                          isSubmenuOpen={true}
                          clicked={() => navigateToRoute(id)}
                          hasSubMenus={hasSubMenus}
                          openSubMenus={openSubMenus}
                          isSidebarOpen={isSidebarOpen}
                          id={id}
                        />
                        {id === 4 &&
                          subMenus.length &&
                          subMenus.map((subMenu: any, index: any) => {
                            return (
                              <div
                                key={index}
                                className={css({
                                  paddingLeft: '10px',
                                  backgroundColor: 'rgb(245, 245, 245)',
                                })}
                              >
                                {openSubMenus && (
                                  <SideBarLink
                                    {...rest}
                                    name={subMenu.name}
                                    clicked={() => {
                                      setSubMenuIsOpen(true);
                                      navigate(subMenu.route);
                                    }}
                                    route={subMenu.route}
                                    icon={subMenu.icon}
                                    iconActive={subMenu.icon}
                                    isActive={isSubMenuOpen}
                                    isSidebarOpen={isSidebarOpen}
                                    id={4}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </React.Fragment>
                    )
                  );
                }
              )}
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={isVersionDetailModalOpen}
        onClose={() => setIsVersionDetailModalOpen(false)}
        overrides={{
          Dialog: {
            style: {
              minWidth: '60vw',
              maxHeight: '80vh',
              overflowY: 'auto',
            },
          },
          Root: {
            style: {
              zIndex: 999,
            },
          },
        }}
      >
        <ModalHeader>Version Details</ModalHeader>
        <ModalBody>
          <div>
            <h1 className={labelStyles}>Version Number: </h1>
            <p className={textStyles}>{currentVersion?.versionNumber}</p>
          </div>
          <div>
            <h1 className={labelStyles}>Short Description: </h1>
            <p className={textStyles}>
              {currentVersion?.versionShortDescription}
            </p>
          </div>
          <div>
            <h1 className={labelStyles}>Release Date: </h1>
            <p className={textStyles}>
              {moment(currentVersion?.releaseDate).format('DD-MM-YYYY')}
            </p>
          </div>
          <div>
            <h1 className={labelStyles}>Release Notes: </h1>
            <p
              className={css({
                fontFamily: 'poppins-regular',
                fontSize: '14px',
                color: colors.darkBlue,
                border: '1px solid #E5E5E5',
                borderRadius: '10px',
                padding: '10px',
              })}
              dangerouslySetInnerHTML={{ __html: currentVersion?.releaseNotes }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsVersionDetailModalOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={warningPopUp} onClose={() => setWarningPopUp(false)}>
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>Changes will be lost if you cancel, Are you sure?</ModalBody>
        <ModalFooter>
          <Button kind={KIND.tertiary} onClick={() => setWarningPopUp(false)}>
            No
          </Button>
          <Button
            onClick={() => {
              setWarningPopUp(false);
              localStorage.removeItem('unsaved');
              navigate(foundRoute);
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SideBar;
