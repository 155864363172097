/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useStyletron } from 'baseui';
import { Button, SHAPE as BUTTON_SHAPE, SIZE } from 'baseui/button';
import './animations.scss';
import { AppContext, DispatcherAction } from 'context';
import Logo from '../../logo';
import bell from '../../../../../assets/icons/notification.png';
import UserProfile from './user-profile';
import colors from 'constants/colors';
import Notifications from './notifications';
import NotificationListener from './notification-listener';
import { Axios } from 'core/services/http';
import { WebSocketContext } from 'context/webSocket';
import soundOptions from 'constants/SoundOptions';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../breadcrumb';
import ModuleIcon from '../../../../../assets/icons/modules-icon.svg';
import ModuleIconWhite from '../../../../../assets/icons/modules-icon-white.svg';
import ModuleBellIcon from '../../../../../assets/icons/module-bell.svg';
import Sale from '../../../../../assets/icons/sale.svg';
import AnalyticsImg from '../../../../../assets/icons/analytics.svg';
import UserLogoutIcon from '../../../../../assets/icons/header-logout.svg';
import UserIcon from '../../../../../assets/icons/user.svg';
import { urls } from 'constants/urls';
import { SidebarProps } from 'modules/shared/interfaces/sidebar.interface';
import { Link } from 'react-router-dom';
import { useSelectedCompanies } from 'context/companies';
import { AuthService } from 'core/services/auth';

interface CompanyOption {
  label: string;
  value: string;
  currency: string; // Each company object has a currency key
}

const CurrencyDropdown: React.FC = () => {
  const {
    selectedCurrency,
    setSelectedCurrency,
    selectedCompanies,
    companiesOptions,
  } = useSelectedCompanies();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let availableCurrencies: string[] = [];

  // Determine available currencies based on the number of companies
  if (companiesOptions.length === 1) {
    // Case 1: Only one company option
    const companyCurrency = companiesOptions[0].currency;
    availableCurrencies =
      companyCurrency === 'AED' ? ['AED'] : [companyCurrency, 'AED'];
  } else if (companiesOptions.length > 1) {
    if (selectedCompanies.length === 1) {
      // Case 2a: Multiple company options but only one selected
      const selectedCompany = companiesOptions.find(
        (company: any) => company.value === selectedCompanies[0]
      );
      if (selectedCompany) {
        const selectedCompanyCurrency = selectedCompany.currency;
        availableCurrencies =
          selectedCompanyCurrency === 'AED'
            ? ['AED']
            : [selectedCompanyCurrency, 'AED'];
      }
    } else {
      // Case 2b: Multiple company options and multiple selected
      availableCurrencies = ['Local', 'AED'];
    }
  }

  // Set default currency with useEffect
  useEffect(() => {
    if (companiesOptions.length === 1) {
      const companyCurrency = companiesOptions[0].currency;
      setSelectedCurrency(companyCurrency);
    } else if (companiesOptions.length > 1) {
      if (selectedCompanies.length === 1) {
        const selectedCompany = companiesOptions.find(
          (company: any) => company.value === selectedCompanies[0]
        );
        if (selectedCompany) {
          setSelectedCurrency(selectedCompany.currency);
        }
      } else {
        if (
          !selectedCurrency ||
          !availableCurrencies.includes(selectedCurrency)
        ) {
          setSelectedCurrency('Local');
        }
      }
    }
  }, [
    setSelectedCurrency,
    companiesOptions,
    selectedCompanies,
    selectedCurrency,
    availableCurrencies,
  ]);

  const handleCurrencySelect = (currency: string) => {
    setSelectedCurrency(currency);
    setIsDropdownOpen(false);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      style={{ position: 'relative', display: 'inline-flex', width: '80px' }}
    >
      <span
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        style={{
          display: 'block',
          padding: '8px',
          backgroundColor: isDropdownOpen ? '#eff0ff' : 'transparent',
          cursor: 'pointer',
          color: 'black',
          fontSize: '15px',
          width: '100%',
          borderRadius: '4px',
          textAlign: 'center',
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = '#eff0ff')
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.backgroundColor = isDropdownOpen
            ? '#eff0ff'
            : 'transparent')
        }
      >
        {selectedCurrency}
      </span>
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            zIndex: '99999',
            width: '100%',
          }}
        >
          {availableCurrencies.map((currency) => (
            <div
              key={currency}
              onClick={() => handleCurrencySelect(currency)}
              style={{ padding: '8px 12px', cursor: 'pointer', color: '#333' }}
            >
              {currency}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CompanyDropdown = ({ isAnalytics = false }: any) => {
  const axios = new Axios().getInstance();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    selectedCompanies,
    setSelectedCompanies,
    setCompaniesOptions,
    companiesOptions,
    setStoresOptions,
    setStoresOptionsByCompanies,
    storesOptions,
  } = useSelectedCompanies();
  const [companies, setCompanies] = useState<CompanyOption[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    state: { user },
  } = useContext(AppContext);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('cs-integrations', {
          params: { userId: user?.id },
        });
        if (response?.data?.data.integrations) {
          const companyOptions = response.data.data.integrations.map(
            (integration: any) => ({
              label: integration.companyId?.name,
              value: integration.id,
              companyId: integration.companyId?._id,
              year: integration.companyId?.analyticsStartingYear,
              currency: integration.companyId?.currency,
            })
          );
          const storesOptions = response.data.data.stores.map((store: any) => ({
            label: store.name,
            id: store.id,
            companyId: store.companyId._id,
          }));
          setCompaniesOptions(companyOptions);
          setStoresOptions(storesOptions);
          setStoresOptionsByCompanies(storesOptions);
          setCompanies(companyOptions);

          // Automatically select integration with companyId.name === 'GGC-UAE' if multiple integrations exist
          if (companyOptions.length > 0 && selectedCompanies.length === 0) {
            setSelectedCompanies(
              companyOptions.map((company: any) => company.value)
            );
          }
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    fetchCompanies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const noneMatch = selectedCompanies.every(
      (item) => !companiesOptions.some((obj: any) => obj.value === item)
    );

    let result;
    if (noneMatch && companiesOptions.length > 0) {
      result = [companiesOptions[0].value];
    } else {
      result = selectedCompanies.filter((item) =>
        companiesOptions.some((obj: any) => obj.value === item)
      );
    }

    if (result.length > 0) {
      localStorage.setItem('selectedCompanies', JSON.stringify(result));
    }
    const companies = companiesOptions
      .filter((integration: any) =>
        selectedCompanies.includes(integration.value)
      )
      .map((company: any) => company.companyId);
    const filteredStores = storesOptions.filter((store: any) => {
      return companies.includes(store.companyId.trim());
    });
    setStoresOptionsByCompanies(filteredStores);
    // eslint-disable-next-line
  }, [selectedCompanies, storesOptions, companiesOptions]);

  const handleSelect = (integrationId: string) => {
    //@ts-ignore

    setSelectedCompanies((prev: string[]) => {
      let newState: string[];
      if (prev.includes(integrationId)) {
        if (prev.length === 1) {
          alert('Cannot remove the last selected company');
          return prev;
        }
        newState = prev.filter((id) => id !== integrationId);
      } else {
        newState = [...prev, integrationId];
      }
      return newState;
    });
  };

  const getDropdownLabel = (): string => {
    let selectedCompanies: string[] = JSON.parse(
      localStorage.getItem('selectedCompanies') || '[]'
    );
    const filteredArray = selectedCompanies.filter(
      (item: any) => typeof item === 'string'
    );

    if (filteredArray.length === 1) {
      const selectedCompany = companies.find(
        (company) => company.value === filteredArray[0]
      );
      return selectedCompany ? selectedCompany.label : 'Select Company';
    } else if (filteredArray.length > 1) {
      return filteredArray.length + ' Companies';
    } else {
      return 'Select Company';
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      style={{
        position: 'relative',
        display: 'inline-flex',
        width: '140px',
        marginLeft: '10px',
      }}
    >
      <span
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        style={{
          display: 'block',
          padding: '8px',
          backgroundColor: isDropdownOpen
            ? isAnalytics
              ? '#eff0ff'
              : '#6699CC'
            : 'transparent',
          cursor: 'pointer',
          color: isDropdownOpen ? 'black' : isAnalytics ? 'black' : 'white',
          fontSize: '15px',
          width: '100%',
          borderRadius: '4px',
          textAlign: 'center',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = isAnalytics
            ? '#eff0ff'
            : '#6699CC';
          e.currentTarget.style.color = '#333';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = isDropdownOpen
            ? isAnalytics
              ? '#eff0ff'
              : '#6699CC'
            : 'transparent';
          e.currentTarget.style.color = isDropdownOpen
            ? 'black'
            : isAnalytics
            ? 'black'
            : 'white';
        }}
      >
        {getDropdownLabel()}
      </span>
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: '100%',
          }}
        >
          {companies.map((company) => (
            <label
              key={company.value}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 12px',
                cursor: 'pointer',
                color: '#333',
              }}
            >
              <input
                type="checkbox"
                checked={selectedCompanies.includes(company.value)}
                onChange={() => handleSelect(company.value)}
                style={{ marginRight: '8px' }}
              />
              {company.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

const Toolbar = ({ isSidebarOpen, setIsSidebarOpen }: SidebarProps) => {
  const {
    state: { user, soundEnabled, selectedSound, breadcrumbs },
  } = useContext(AppContext);
  const foundOption = soundOptions.find(
    (option) => option.id === selectedSound
  );
  const navigate = useNavigate();
  const notificationSound = foundOption
    ? foundOption.url
    : 'http://codeskulptor-demos.commondatastorage.googleapis.com/pang/pop.mp3';
  const [noMoreApiCalls, setNoMoreApiCalls] = useState(false); // Flag to prevent further API calls when no more data is available

  const [active, setActive] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState<Array<any>>([]);
  const [isModuleOpen, setIsModuleOpen] = useState<boolean>(false);
  const panelRef = useRef<HTMLDivElement | null>(null); // Ref for the notification panel
  const bellRef = useRef<HTMLButtonElement | null>(null);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);
  const [css] = useStyletron();
  const [notificationType, setNotificationType] = useState('new');
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const axios = new Axios().getInstance();
  const { socket } = useContext(WebSocketContext);
  const location = useLocation();
  //const dropDownRef = useRef<HTMLUListElement>(null);
  const authService = new AuthService();
  const { dispatcher } = useContext(AppContext);

  const getPagePageUrl = (str: string) => {
    if (str === '/analytics') {
      return 'COGS Summary';
    } else {
      if (str?.includes('cogs')) {
        // Replace dashes with spaces and split into words
        let words = str.replace(/-/g, ' ').substring(1).split(' ');

        // Capitalize each word
        words = words.map((word, index) => {
          // If the word is 'cogs' and it's the first word, capitalize it
          if (index === 0 && word.toLowerCase() === 'cogs') {
            return 'COGS';
          }
          // Capitalize the first letter of each word
          return word.charAt(0).toUpperCase() + word.slice(1);
        });

        // Join the words back into a string
        return words.join(' ');
      } else {
        // Capitalize each word for general cases
        return str
          .replace(/-/g, ' ')
          .substring(1)
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
    }
  };

  const parentWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100vw',
    position: 'fixed',
    top: '0',
    left: '0',
    padding: '17.5px 20px',
    zIndex: 999,
    background: colors.primaryBlue,
    boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
    '@media (max-width: 600px)': {
      padding: '14px 10px',
    },
    '@media (max-width: 500px)': {
      zIndex: 1000,
    },
  });
  const bellWrapper = css({
    border: 'none',
    outline: 'none',
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    transform: 'rotate(28deg)',
    borderRadius: '50%',
    padding: '6px',
    transition: 'all 0.3s ease-in-out',
  });
  const notification = css({
    width: '100%',
    filter: 'brightness(0) invert(1)',
  });
  const activeNotification = css({
    border: 'none',
    outline: 'none',
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    transform: 'rotate(28deg)',
    borderRadius: '50%',
    padding: '6px',
    transition: 'all 0.3s ease-in-out',
  });
  const notificationCount = css({
    width: '16px',
    height: '16px',
    fontSize: '12px',
    top: '-2px',
    position: 'absolute',
    zIndex: 1,
    left: '18px',
    transform: 'rotate(-28deg)',
    backgroundColor: colors.lightGreen,
    color: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '500',
    borderRadius: '50%',
  });
  const headerStyles = css({
    width: isSidebarOpen ? 'calc(100% - 240px)' : 'calc(100% - 72px)',
    borderBottom: '1px solid rgba(48, 51, 147, 0.1)',
    margin: '0 0 0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'space-between',
    padding: '8px 27px',
    transition: 'all 400ms ease',
  });
  const HeaderPageName = css({
    fontSize: '20px',
    lineHeight: '30px',
    fontFamily: 'poppins-medium',
    color: 'var(--black)',
    margin: '0 0 16px',
    textTransform: 'capitalize',
  });
  const headerActions = css({
    display: 'flex',
    alignItems: 'center',
  });
  const headerActionList = css({
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    columnGap: '10px',
    margin: '0',
    padding: '0',
  });
  const headerActionListItem = css({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
  });
  const headerNotifiction = css({
    position: 'relative',
  });
  const headerNotifictionCount = css({
    position: 'absolute',
    top: '-7px',
    right: '-15px',
    display: 'flex',
    backgroundColor: 'var(--lightRed)',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
  });

  const ModuleDropdown = css({
    backgroundColor: '#fff',
    width: 'auto', // Set width based on content
    minWidth: '100px', // Minimum width to prevent collapsing too much
    borderRadius: '4px',
    border: '1px solid rgba(48, 51, 147, 0.1)',
    position: 'absolute',
    top: '83px',
    display: isModuleOpen ? 'block' : 'none',
    zIndex: '9',
    left: 'calc(100% - 288px)',
  });

  const ModuleDropdownBody = css({
    padding: '12px 16px 16px',
  });

  const ModuleDropdownContent = css({
    display: 'flex', // Use flex to display service boxes side by side
    flexDirection: 'row', // Horizontal layout for service boxes
    gap: '16px', // Space between each service box
    alignItems: 'center',
  });

  const ModuleDropdownServiceBox = css({
    display: 'flex',
    flexDirection: 'column', // Keep the icon and text vertical inside the box
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '9.5px 1px',
  });

  const ModuleDropdownServiceTitle = css({
    margin: '8px 0 0',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'var(--black)',
    fontWeight: '400',
    whiteSpace: 'nowrap', // Prevents text wrapping
  });

  const headerActionModule = css({
    cursor: 'pointer',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
  });
  const UserDropdown = css({
    backgroundColor: '#fff',
    // width: '100%',
    borderRadius: '4px',
    border: '1px solid rgba(48, 51, 147, 0.1)',
    position: 'absolute',
    top: '83px',
    right: '10px',
    display: isUserDropdownOpen ? 'block' : 'none',
    zIndex: '9',
  });
  const UserDropdownLink = css({
    display: 'flex',
    textDecoration: 'none',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    padding: '11.5px 12px',
    gap: '8px',
    alignItems: 'center',
  });

  const resolveButtonText = (): string => {
    if (user?.firstName && user?.lastName) {
      return (
        user.firstName.substring(0, 1) + user.lastName.substring(0, 1)
      ).toUpperCase();
    }
    if (user?.firstName) {
      return user.firstName.substring(0, 2).toUpperCase();
    }
    return '';
  };

  const getNotifications = async () => {
    try {
      const response = await axios.get('/notifications/list', {
        params: {
          pageNumber: 1,
          pageSize: 25,
          type: notificationType,
        },
      });
      if (response?.data?.success) {
        setNotifications(response.data.data.notifications);
        setUnreadCount(response.data.data.unreadCount);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.log(message);
        });
      }
    }
  };

  const playSound = () => {
    if (soundEnabled) {
      const audio = new Audio(notificationSound);
      audio
        .play()
        .then(() => {
          setTimeout(() => {
            audio.pause();
            audio.currentTime = 0;
          }, 3000);
        })
        .catch((error) => {
          console.error('Error playing audio:', error);
        });
    }
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationType]);
  const handleScroll = async () => {
    const modal: any = document.getElementsByClassName('main-wrapper')[0];
    const scrollHeight = modal.scrollHeight;
    const scrollTop = modal.scrollTop;
    const clientHeight = modal.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 200) {
      setNoMoreApiCalls(false);
    }
    if (
      scrollTop + clientHeight >= scrollHeight - 20 &&
      !loading &&
      !noMoreApiCalls
    ) {
      try {
        setLoading(true);
        const response = await axios.get('/notifications/list', {
          params: {
            pageNumber: pageNumber + 1,
            pageSize: 25,
            type: notificationType,
          },
        });
        const newNotifications = response?.data?.data?.notifications || [];
        if (response?.data?.success && newNotifications.length > 0) {
          setNotifications(response.data.data.notifications);
          setPageNumber((prev) => prev + 1);
        } else {
          setNoMoreApiCalls(true);
        }
      } catch (error: any) {
        const errors = error?.response?.data?.errors;
        if (errors?.length) {
          errors.forEach((message: string) => {
            console.log(message);
          });
        }
      } finally {
        setTimeout(() => {
          setLoading(false);
          setNoMoreApiCalls(true);
        }, 500);
      }
    }
  };
  useEffect(() => {
    if (socket) {
      socket.connect();
      socket.on('new-notification', () => {
        getNotifications();
        playSound();
      });

      return () => {
        socket.off('new-notification');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    const clickListener = handleOutsideClick();
    document.addEventListener('mousedown', clickListener);
    return () => document.removeEventListener('mousedown', clickListener);
  }, []);
  const handleOutsideClick = () => (event: MouseEvent) => {
    if (
      panelRef.current &&
      !panelRef.current.contains(event.target as Node) &&
      bellRef.current &&
      !bellRef.current.contains(event.target as Node)
    ) {
      setActive(false);
    }
  };

  const logoutClickedHandler = async () => {
    localStorage.removeItem('selectedCompanies');
    await axios.post('/auth/signOut');
    authService.logout();
    dispatcher(DispatcherAction.Logout);
    navigate('/');
  };

  const handleCollapsibleDropdown = (type: string) => {
    if (type === 'module') {
      setIsUserDropdownOpen(false);
      setIsModuleOpen(!isModuleOpen);
    } else if (type === 'user') {
      setIsModuleOpen(false);
      setIsUserDropdownOpen(!isUserDropdownOpen);
    }
  };

  return (
    <React.Fragment>
      {urls?.includes(location?.pathname) ? (
        <div>
          <div
            className={`${headerStyles} font-family-poppins`}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <div className={HeaderPageName}>
                {getPagePageUrl(location?.pathname)}
              </div>
              <Breadcrumb items={breadcrumbs} />
            </div>

            <div className={`${headerActions} ${headerActionList}`}>
              <CurrencyDropdown />
              <CompanyDropdown isAnalytics={true} />
              <button
                ref={bellRef}
                className={`${headerNotifiction} ${headerActionListItem}`}
              >
                {unreadCount > 0 ? (
                  <span className={headerNotifictionCount}>{unreadCount}</span>
                ) : null}
                <img
                  id="bellIcon"
                  src={ModuleBellIcon}
                  alt={'nofitaction'}
                  onClick={() => {
                    setIsUserDropdownOpen(false);
                    setIsModuleOpen(false);
                    setActive(!active);
                    setNoMoreApiCalls(false);
                  }}
                />
              </button>
              <div
                className={headerActionListItem}
                style={{
                  marginLeft: '20px',
                }}
              >
                <button
                  type="button"
                  className={headerActionModule}
                  onClick={() => handleCollapsibleDropdown('module')}
                >
                  <img src={ModuleIcon} alt="" />
                </button>
              </div>
              <Button
                shape={BUTTON_SHAPE.round}
                size={SIZE.compact}
                onClick={() => handleCollapsibleDropdown('user')}
                style={{
                  marginLeft: '10px',
                  backgroundColor: 'black',
                  color: 'white',
                  width: '40px', // Adjust the width to make it a circle
                  height: '40px', // The height should be the same as the width
                  borderRadius: '50%', // Ensures the button is circular
                }}
              >
                {resolveButtonText()}
              </Button>
              {isModuleOpen && (
                <div className={ModuleDropdown}>
                  {/* Dropdown Body */}
                  <div className={ModuleDropdownBody}>
                    <div className={ModuleDropdownContent}>
                      {/* Procurement */}
                      <div
                        className={`
                          ${ModuleDropdownServiceBox} 
                          module-service-box`}
                        onClick={() => {
                          setIsModuleOpen(false);
                          navigate('/dashboard');
                        }}
                      >
                        <img src={Sale} alt="Sale" />
                        <h4 className={ModuleDropdownServiceTitle}>
                          Procurement
                        </h4>
                      </div>

                      {/* Analytics */}
                      <div
                        className={`
                          ${ModuleDropdownServiceBox} 
                          module-service-box`}
                        onClick={() => {
                          setIsModuleOpen(false);
                          navigate('/analytics');
                        }}
                      >
                        <img src={AnalyticsImg} alt="Analytics" />
                        <h4 className={ModuleDropdownServiceTitle}>
                          Analytics
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isUserDropdownOpen && (
                <div className={UserDropdown}>
                  <ul className={`list-normal`}>
                    <li>
                      <Link
                        to="my-account"
                        className={UserDropdownLink}
                        onClick={() => setIsUserDropdownOpen(false)}
                      >
                        <img
                          src={UserIcon}
                          alt="my account"
                          width="18"
                          height="18"
                        />
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={UserDropdownLink}
                        style={{ color: 'var(--lightRed)' }}
                        onClick={logoutClickedHandler}
                      >
                        <img
                          src={UserLogoutIcon}
                          alt="user"
                          width="18"
                          height="18"
                        />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={`${parentWrapperStyles}`}>
          <div className={css({ display: 'flex', alignItems: 'center' })}>
            <div style={{ cursor: 'pointer' }}></div>
            <div>
              <Logo height={40} clicked={() => {}} />
            </div>
          </div>
          <div
            className={css({
              display: 'flex',
              marginLeft: 'auto',
              marginRight: '24px',
              gap: '15px',
              alignItems: 'center',
            })}
          >
            {/* <CurrencyDropdown /> */}
            <CompanyDropdown />
            <button
              ref={bellRef}
              className={!active ? bellWrapper : activeNotification}
              style={{
                backgroundColor: active ? 'rgba(0, 0, 0, 0.4)' : 'transparent',
              }}
            >
              {unreadCount > 0 ? (
                <span className={notificationCount}>{unreadCount}</span>
              ) : null}
              <img
                id="bellIcon"
                src={bell}
                alt={'nofitaction'}
                onClick={() => {
                  setActive(!active);
                  setNoMoreApiCalls(false);
                }}
                className={notification}
              />
            </button>
            <div className={headerActionListItem}>
              <button
                type="button"
                className={headerActionModule}
                onClick={() => handleCollapsibleDropdown('module')}
              >
                <img src={ModuleIconWhite} alt="" />
              </button>
            </div>
            {/* Module Dropdown */}
            {isModuleOpen && (
              <div className={ModuleDropdown}>
                {/* Dropdown Body */}
                <div className={ModuleDropdownBody}>
                  <div className={ModuleDropdownContent}>
                    {/* Procurement */}
                    <div
                      className={`
                      ${ModuleDropdownServiceBox} 
                      module-service-box`}
                      onClick={() => {
                        setIsModuleOpen(false);
                        navigate('/dashboard');
                      }}
                    >
                      <img src={Sale} alt="Sale" />
                      <h4 className={ModuleDropdownServiceTitle}>
                        Procurement
                      </h4>
                    </div>

                    {/* Analytics */}
                    <div
                      className={`
                        ${ModuleDropdownServiceBox} 
                        module-service-box`}
                      onClick={() => {
                        setIsModuleOpen(false);
                        navigate('/analytics');
                      }}
                    >
                      <img src={AnalyticsImg} alt="Analytics" />
                      <h4 className={ModuleDropdownServiceTitle}>Analytics</h4>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <UserProfile>
              <Button shape={BUTTON_SHAPE.circle} size={SIZE.compact}>
                {resolveButtonText()}
              </Button>
            </UserProfile>
          </div>
        </div>
      )}
      <div ref={panelRef}>
        {active && (
          <Notifications
            notificationData={notifications}
            refetch={getNotifications}
            handleScroll={handleScroll}
            setNotificationType={setNotificationType}
            loading={loading}
          />
        )}
      </div>
      <NotificationListener refetch={getNotifications} />
    </React.Fragment>
  );
};

export default Toolbar;
