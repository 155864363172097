/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import * as xlsx from 'xlsx';
import Breadcrumb from 'modules/shared/components/breadcrumb';
import CustomIconButton from 'modules/shared/components/icon-button';
import ordersIconURL from 'assets/icons/orders.svg';
import { Axios } from 'core/services/http';
import Pagin from './components/pagination';
import FilterList from './components/search';
import { AppContext } from 'context';
import { UserRole } from 'modules/shared/enums/user-role.enum';
import ReportList from 'modules/shared/components/report-list';
import { toaster } from 'baseui/toast';
import config from 'config';
import colors from 'constants/colors';
import moment from 'moment';
import print from 'assets/icons/print.png';
import { SIZE, Spinner } from 'baseui/spinner';
import { dateFilters, dates } from 'modules/shared/constant';
import useFilterLayoutData from 'core/hooks/useFilterLayoutData';
import useLoader from 'core/hooks/loader';
import { exportFileOptions } from 'constants/options';
import SelectOption from 'modules/shared/components/selectoption';
import ReportingInternalTableLayout from 'layout/ReportingInternalTableLayout';

const BREADCRUMB_ITEMS = [
  { name: 'Reports', route: '/reports' },
  {
    name: 'Expected Delivery Report',
    route: '/reports/invoiceTodayListReport',
  },
];

const EACH_REPORT_LIST_HEADERS = [
  'Prod Code',
  'Prod Name',
  'Pack Uni',
  'Price',
  'Qty',
  'Amount',
];

const OrderListTodayListReport = () => {
  const { store, setStore, stores, brands, brand, setBrand }: any =
    useFilterLayoutData();
  const { loading, cancelLoading, triggerLoading, printing } = useLoader();
  const [ordersList, setOrdersList] = React.useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numPages, setNumPages] = React.useState(1);
  const [pageSize] = React.useState<any>([{ label: 20, id: '1' }]);
  const [startDate, setStartDate] = React.useState<any>([new Date()]);
  const [endDate, setEndDate] = React.useState<any>([new Date()]);
  const [dateOption, setDateOption] = React.useState<any>([]);
  const [dateFilter, setDateFilter] = React.useState<any>([]);
  const [orderStateKey] = React.useState('0');
  const [typeFilter] = React.useState<any>([]);
  const [searchValue] = React.useState('');
  const [allOrdersList, setAllOrdersList] = React.useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [css] = useStyletron();
  const axios = new Axios().getInstance();
  const [disableFilter] = React.useState<boolean>(false);
  const [exportValue, setExportValue] = useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const [controller, setController] = React.useState<any>(
    new AbortController()
  );
  const {
    state: {
      user: { role },
    },
  } = React.useContext(AppContext);
  const isUserByRole = role === UserRole.User;

  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });
  const actionsWrapper = css({
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'space-between',
    marginTop: '-10px',
    gap: '10px',
    // float: 'right',
  });
  const printCustomButtonWrapper = css({
    marginLeft: '0px',
    marginRight: '8px',
    marginBottom: '8px',
  });
  const listWrapper = css({
    padding: '12px',
    '@media (max-width: 540px)': {
      overflow: 'scroll',
    },
  });

  const wrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    borderRadius: '10px',
    backgroundColor: colors.white,
    padding: '6px 12px',
    cursor: 'pointer',
    height: 'fit-content',
    '@media (max-width: 600px)': {
      padding: '4px 10px',
    },
  });

  const headerTitle = css({
    flex: 1,
  });
  const headerTitleLarge = css({
    flex: 1.5,
  });

  const headerTitleLast = css({
    flex: 0.5,
  });

  const params: any = {
    period: JSON.stringify({
      label: dateOption.length > 0 ? dateOption[0]?.id : 'custom',
      startDate: startDate[0]
        ? moment(moment(startDate[0]).format('YYYY-MM-DD').toString())
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss[Z]')
        : moment().utc().clone().startOf('day').format(),
      endDate: startDate[0]
        ? moment(moment(startDate[0]).format('YYYY-MM-DD').toString())
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss[Z]')
        : moment().utc().clone().endOf('day').format(),
    }),
    filterBy: 'SupplyDate',
    store:
      store.length > 0 ? store.map((st: any) => (st?.id ? st.id : st)) : '',
    type: typeFilter.length > 0 ? typeFilter[0]?.id : 'All',
    search: searchValue,
    orderStatus: 'Released',
    brands: brand.map((brand: any) => brand.id),
  };
  const getAllListData = async () => {
    try {
      setIsLoading(true);
      const response: any = await fetch(
        `${config.API_URL}/report-pdf?` +
          new URLSearchParams({
            ...params,
            report: 'delivery',
          }),
        {
          method: 'GET',
          signal: controller.signal,
          headers: {
            authorization: `Bearer ${localStorage.getItem('auth-token')}`,
          },
        }
      );

      if (response.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        if (exportValue) {
          setExportValue(null);
          return url;
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getInvoiceLists = async () => {
    console.error(store);

    try {
      const paramerters = {
        ...params,
        pageSize: pageSize[0]?.label,
        pageNumber: currentPage,
      };
      const response = await getInoiceData(paramerters);
      if (response?.data?.success) {
        setOrdersList(response?.data?.data?.items);
        setNumPages(response?.data?.data?.meta?.totalPages);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const getInoiceData = async (params: any) => {
    triggerLoading();
    try {
      const response = await axios.get('/report', {
        params: params,
        signal: controller.signal,
      });
      cancelLoading();
      return response;
    } catch (error: any) {
      cancelLoading();
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => console.error(message));
      }
    }
  };

  const onSubmit = () => {
    getInvoiceLists();
  };

  const getExportData = async () => {
    try {
      const response = await axios.get('/report', {
        params: params,
        signal: controller.signal,
      });
      if (response?.data?.success) {
        setAllOrdersList(response?.data?.data?.items);
      }
    } catch (error) {}
  };

  async function exportToCSV() {
    let dataToExport: Array<any> = [];
    try {
      const response: any = await getExportData();
      allOrdersList?.forEach(
        ({
          supplierName,
          createdAt,
          date,
          storeName,
          orderLines,
          supplyDate,
        }) => {
          const obj: any = {
            'Supplier Name': supplierName,
            'Supply Date': supplyDate,
            'Total Amount': storeName,
            'Order No': createdAt,
            'Order Date': date,
          };
          const orderLine: any = [];
          const orderLinerHeader = {
            'Supplier Name': 'Product Name',
            'Supply Date': 'Prodct Code',
            'Total Amount': 'Amount',
            'Order No': 'Packaging Unit',
            'Order Date': 'Quantity',
          };
          orderLines.forEach((element: any) => {
            const orderObj = {
              'Supplier Name': element.prodName,
              'Supply Date': element.code,
              'Total Amount': element.orderQuantity * element.priceWoVAT,
              'Order No': element.packagingUnit,
              'Order Date': element.orderQuantity,
            };
            orderLine.push(orderObj);
          });
          dataToExport.push(obj, orderLinerHeader, ...orderLine);
        }
      );
      const worksheet = xlsx.utils.json_to_sheet(dataToExport);
      const columnWidths = [
        { wch: 15 },
        { wch: 20 },
        { wch: 25 },
        { wch: 15 },
        { wch: 20 },
      ];
      worksheet['!cols'] = columnWidths;
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Invoice Lists');
      if (dataToExport.length > 0 && response) {
        xlsx.writeFile(workbook, 'invoice_list.xlsx');
      }
      setIsLoading(false);
      setIsExportLoading(false);
    } catch (err: any) {
      toaster.negative(err.message, {
        autoHideDuration: 4000,
      });
    }
  }

  useEffect(() => {
    Promise.all([getInvoiceLists()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStateKey, currentPage, pageSize]);

  useEffect(() => {
    if (searchValue === '') {
      getInvoiceLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const orderLinesToRender = (data: any) => {
    return data?.map((item: any) => {
      return [
        item.code,
        item.prodName,
        item.packagingUnit,
        item.priceWoVAT.toFixed(2),
        item.orderQuantity,
        (item?.priceWoVAT * item.orderQuantity).toFixed(2),
      ];
    });
  };

  const toggleCollapse = (index: number) => {
    const selectedRow = ordersList[index];
    const start = ordersList.slice(0, index);
    const end = ordersList.slice(index + 1);
    setOrdersList([
      ...start,
      { ...selectedRow, isExpanded: !selectedRow.isExpanded },
      ...end,
    ]);
  };
  const loader = css({
    height: 'calc(100vh - 380px)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  });
  const selectOverrides = {
    ControlContainer: {
      style: () => ({
        border: '1px solid rgba(13, 38, 107, 0.1)',
        borderRadius: '10px',
        backgroundColor: colors.white,
        color: colors.darkBlue,
      }),
    },
  };
  const cancelProcessing = (event?: any, isEmailProcessStarted?: boolean) => {
    if (!isEmailProcessStarted) {
      setExportValue(null);
    }
    controller.abort();
    setIsLoading(false);
    setIsExportLoading(false);
    const newController = new AbortController();
    setController(newController);
  };
  const downloadPdf = async () => {
    const file: any = await getAllListData();
    if (file) {
      var anchor: any = document.createElement('a');
      anchor.id = 'invoice-list-report-pdf-anchor';
      anchor.href = file;
      anchor.style.display = 'none';
      anchor.download = 'expected-delivery-report.pdf';
      document.body.appendChild(anchor);
      setIsLoading(false);
      setIsExportLoading(false);
      setExportValue(null);
      anchor.click();
    }
  };
  const downloadFile = () => {
    if (exportValue[0]?.id === 1) {
      return downloadPdf();
    }
    if (exportValue[0]?.id === 2) return exportToCSV();
  };
  useEffect(() => {
    if (exportValue) {
      setIsLoading(false);
      setIsExportLoading(true);
      downloadFile();
    }
  }, [exportValue]);
  const emailMeFile = async () => {
    cancelProcessing({}, true);
    setExportValue(null);
    if (exportValue[0]?.id === 1) {
      try {
      } catch (error) {}
    }
    if (exportValue[0]?.id === 2) {
      try {
      } catch (error) {}
    }
  };
  return (
    <ReportingInternalTableLayout
      isLoading={isLoading}
      isExportLoading={isExportLoading}
      cancelProcessing={cancelProcessing}
      type={exportValue}
      emailMeFile={emailMeFile}
    >
      <div className={css({ marginTop: '70px' })}>
        <div className={breadcrumbWrapper}>
          <img className={iconStyles} src={ordersIconURL} alt="Order" />
          <Breadcrumb items={BREADCRUMB_ITEMS} />
        </div>
        <div className={listWrapper}>
          <FilterList
            dates={dates}
            dateFilters={dateFilters}
            stores={stores}
            brands={brands}
            brand={brand}
            setBrand={setBrand}
            store={store}
            startDate={startDate}
            endDate={endDate}
            dateOption={dateOption}
            dateFilter={dateFilter}
            setStore={setStore}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setDateOption={setDateOption}
            setDateFilter={setDateFilter}
            onSubmit={onSubmit}
            disableFilter={disableFilter}
            setIsFilterOpen={setIsFilterOpen}
          />
        </div>
        <div className={actionsWrapper}>
          <div
            style={{ display: 'flex', marginLeft: 'auto', marginRight: '0px' }}
          >
            {!isUserByRole && (
              <div className={printCustomButtonWrapper}>
                {!printing ? (
                  <div className={wrapperStyles}>
                    <span> Printing...</span>
                    <Spinner $size={SIZE.small} />
                  </div>
                ) : (
                  <CustomIconButton
                    icon={print}
                    name="Print"
                    onClick={getAllListData}
                    isDisable={false}
                  />
                )}
              </div>
            )}
            <SelectOption
              name="Export"
              exportFileOptions={exportFileOptions}
              value={exportValue}
              selectOption={(params) => {
                setExportValue(params.value);
              }}
              selectOverrides={selectOverrides}
            />
          </div>
        </div>
        {loading ? (
          <div className={loader}>
            <Spinner $size="50" />
          </div>
        ) : (
          <div className={listWrapper} style={{ marginTop: '-5px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <div className={headerTitle}>
                <label
                  style={{
                    display: 'block',
                    padding: '12px 0px',
                    fontWeight: 'bold',
                    backgroundColor: '#deeeff',
                  }}
                >
                  Order No
                </label>
              </div>
              <div className={headerTitleLarge}>
                <label
                  style={{
                    display: 'block',
                    padding: '12px 0px',
                    fontWeight: 'bold',
                    paddingLeft: '10px',
                    backgroundColor: '#deeeff',
                  }}
                >
                  Supplier Name
                </label>
              </div>
              <div className={headerTitle}>
                <label
                  style={{
                    display: 'block',
                    padding: '12px 0px',
                    fontWeight: 'bold',
                    backgroundColor: '#deeeff',
                  }}
                >
                  Order Date
                </label>
              </div>
              <div className={headerTitle}>
                <label
                  style={{
                    display: 'block',
                    padding: '12px 0px',
                    fontWeight: 'bold',
                    backgroundColor: '#deeeff',
                  }}
                >
                  Supply Date
                </label>
              </div>
              <div className={headerTitle}>
                <label
                  style={{
                    display: 'block',
                    padding: '12px 0px',
                    fontWeight: 'bold',
                    backgroundColor: '#deeeff',
                  }}
                >
                  Store Name
                </label>
              </div>

              <div className={headerTitleLast}>
                <label
                  style={{
                    display: 'block',
                    padding: '12px 0px',
                    fontWeight: 'bold',
                    backgroundColor: '#deeeff',
                  }}
                >
                  Total Amount
                </label>
              </div>
            </div>
            {ordersList.map((data: any, index: number) => (
              <div className="class-main-row">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px',
                    marginBottom: '5px',
                  }}
                >
                  <div className={headerTitle}>
                    <div className="mainOrderRow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                          rotate: data.isExpanded ? '' : '270deg',
                          marginLeft: '2px',
                          marginRight: '10px',
                          transition: 'all 0.5s ease',
                          cursor: 'pointer',
                        }}
                        onClick={() => toggleCollapse(index)}
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 1 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                      {data.orderNumber}
                    </div>
                  </div>
                  <div className={headerTitleLarge}>
                    <div className="mainOrderRow2">{data.supplierName}</div>
                  </div>
                  <div className={headerTitle}>
                    <div className="mainOrderRow">{data.date}</div>
                  </div>
                  <div className={headerTitle}>
                    <div className="mainOrderRow">{data.supplyDate}</div>
                  </div>
                  <div className={headerTitle}>
                    <div className="mainOrderRow">{data.storeName}</div>
                  </div>

                  <div className={headerTitleLast}>
                    <div
                      className="mainOrderRow"
                      style={{ textAlign: 'right', marginRight: '20px' }}
                    >
                      {data.value}
                    </div>
                  </div>
                </div>
                {data.isExpanded && (
                  <div
                    style={{
                      width: '99%',
                      marginLeft: 'auto',
                    }}
                  >
                    <ReportList
                      reportHeader={EACH_REPORT_LIST_HEADERS}
                      reportData={orderLinesToRender(data?.orderLines)}
                      type="Expected Delivery Report"
                      type2="EDR"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div
          className={listWrapper}
          style={{ display: 'flex', float: 'right' }}
        >
          <Pagin
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numPages={numPages}
            pageSize={pageSize}
          />
        </div>
      </div>
    </ReportingInternalTableLayout>
  );
};

export default OrderListTodayListReport;
