import React from 'react';
import { DatePicker } from 'baseui/datepicker';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'styletron-react';

import './index.scss';
import colors from 'constants/colors';
import SelectWithCheckboxes from 'modules/shared/components/multi-select';
import { useSelectedCompanies } from 'context/companies';

interface IProps {
  stores: Array<any>;
  store: any;
  setStore: any;
  startDate: Array<any>;
  dateOption: any;
  setStartDate: any;
  setEndDate: any;
  onSubmit: any;
  setIsFilterOpen: any;
}

const FilterList = ({
  stores,
  store,
  setStore,
  startDate,
  dateOption,
  setStartDate,
  setEndDate,
  onSubmit,
  setIsFilterOpen,
}: IProps) => {
  const [css] = useStyletron();
  const { storesOptionsByCompanies } = useSelectedCompanies();

  const storeOptions = storesOptionsByCompanies.map(({ id, label }: any) => ({
    label,
    id,
  }));

  const buttonStyle = css({
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
    backgroundColor: colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });

  React.useEffect(() => {
    // resetInputs();
  }, [dateOption]);

  return (
    <div className="create-order-list">
      <div>
        <FlexGrid
          flexGridColumnCount={[1, 2, 3]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Date</div>
              <DatePicker
                disabled={false}
                required={true}
                value={startDate}
                onChange={({ date }) => {
                  setStartDate(Array.isArray(date) ? date : [date]);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 9999 },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="input-wrapper">
              <div className="input-label">Stores</div>
              <SelectWithCheckboxes
                options={storeOptions}
                disabled={false}
                store={store}
                handleChange={(params: any) => setStore(params)}
              />
            </div>
          </FlexGridItem>
          <FlexGridItem>
            <div className="invoice-button-wrapper">
              <button type="button" className={buttonStyle} onClick={onSubmit}>
                Apply
              </button>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </div>
    </div>
  );
};

export default FilterList;
