import colors from 'constants/colors';
import React from 'react';
import { useStyletron } from 'styletron-react';
import searchIconURL from 'assets/icons/search.svg';

interface IProps {
  placeholder: string;
  buttonName: string;
  inputValue?: string;
  inputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchClicked?: () => void;
  emptySearch?: () => void;
  setSearchValue?: any;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  loading?: boolean;
}

const CustomSearch = ({
  placeholder,
  buttonName,
  inputValue,
  inputChange,
  setSearchValue,
  searchClicked,
  handleKeyDown,
  loading,
  emptySearch,
}: IProps) => {
  const [css] = useStyletron();
  const searchWrapper = css({
    display: 'flex',
    border: '1px solid rgba(13, 38, 107, 0.1)',
    backgroundColor: colors.white,
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '10px',
    overflowX: 'hidden',
    overflowY: 'visible',
    position: 'relative',
    minHeight: '43px',
  });
  const iconInputWrapper = css({
    display: 'flex',
    height: '100%',
    width: '0px',
    alignItems: 'center',
    padding: '10px 12px',
  });
  const iconStyle = css({ width: '20px', height: '20px', marginRight: '8px' });
  const inputStyle = css({
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    // minWidth: '260px',
  });
  const buttonWrapper = css({
    padding: '2px',
    display: 'flex',
  });
  const buttonStyle = css({
    padding: '4px 12px',
    outline: 'none',
    border: 'none',
    backgroundColor: loading ? colors.red : colors.primaryGreen,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '8px',
    color: colors.white,
    fontFamily: 'poppins-regular',
    fontSize: '14px',
    cursor: 'pointer',
  });
  const crossButtonStyle = css({
    outline: 'none',
    height: '20px',
    width: '20px',
    border: 'none',
    backgroundColor: colors.mediumGray,
    boxShadow: '0px 8px 20px rgba(9, 176, 140, 0.3)',
    borderRadius: '100%',
    color: colors.white,
    fontFamily: 'poppins-regular',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '2px 8px 0',
    fontWeight: 'bolder',
    position: 'absolute',
    top: '10px',
    right: '75px',
    textAlign: 'center',
  });
  return (
    <div className={searchWrapper}>
      <div className={iconInputWrapper}>
        <img className={iconStyle} src={searchIconURL} alt="Search" />
        <input
          className={inputStyle}
          type="text"
          placeholder={placeholder}
          onChange={inputChange}
          value={inputValue}
          onKeyDown={(e) => handleKeyDown && handleKeyDown(e)}
        />
      </div>
      <div className={buttonWrapper}>
        {inputValue && (
          <div className={crossButtonStyle} onClick={emptySearch}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#fff"
              viewBox="0 0 16 16"
            >
              <path
                fill="#fff"
                stroke="#fff"
                strokeWidth="1.5"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
        )}

        <input
          className={buttonStyle}
          type="button"
          value={buttonName}
          onClick={searchClicked}
        />
      </div>
    </div>
  );
};

export default CustomSearch;
