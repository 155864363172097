import classNames from 'classnames';
import './index.scss';
import PrintFilter from './search';

interface IProps {
  reportHeader: Array<any>;
  reportData: Array<Array<any>>;
  store: any;
  startDate: Array<any>;
  endDate: Array<any>;
  dateOption: any;
  dateFilter: any;
}

const PrintInvoiceTemplate = ({
  reportHeader,
  reportData,
  store,
  startDate,
  endDate,
  dateOption,
  dateFilter,
}: IProps) => {
  return (
    <div>
      <h3>Invoice Report</h3>
      <div className="header">
        <PrintFilter
          store={store}
          startDate={startDate}
          endDate={endDate}
          dateOption={dateOption}
          dateFilter={dateFilter}
        />
      </div>
      <div className="report-list">
        <div className="report-list-header">
          {reportHeader.map((item, index) => (
            <div
              key={`header_${index}`}
              className={classNames({
                'report-list-header-columnNumber': index === 5 || index === 6,
                'report-list-header-column': !(index === 5 || index === 6),
              })}
            >
              <div
                className="report-list-header-column__label"
                style={{ marginTop: '5px' }}
              >
                {item}
              </div>
            </div>
          ))}
        </div>
        <div className="report-list-rows">
          {reportData.map((item, i) => (
            <div key={`data_${i}`} className="report-list-row">
              {item.map((_item, j) => (
                <div
                  key={`data_${i}_${j}`}
                  className={classNames({
                    'report-list-row-columnNumber': j === 5 || j === 6,
                    'report-list-row-column': !(j === 5 || j === 6),
                  })}
                >
                  <div
                    className="report-list-row-column__label"
                    style={{ fontSize: '8px' }}
                  >
                    {_item}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
};

export default PrintInvoiceTemplate;
