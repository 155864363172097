/* eslint-disable react-hooks/exhaustive-deps */
import { AppContext, DispatcherAction } from 'context';
import { useContext, useLayoutEffect } from 'react';

const FoodCost = () => {
  const { dispatcher } = useContext(AppContext);

  const BREADCRUMB_ITEMS = [
    { name: 'Analytics', route: '/analytics' },
    { name: 'Food Cost', route: '/foodcost' },
  ];

  useLayoutEffect(() => {
    dispatcher(DispatcherAction.BreadCrumbs, BREADCRUMB_ITEMS);
  }, []);

  return <>FoodCoast</>;
};

export default FoodCost;
