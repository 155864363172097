/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import AnalyticsFilters from 'modules/analytics/components/filters';
import {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useStyletron } from 'styletron-react';
import ArrangeIcon from '../../assets/icons/up-down-arrow.svg';
import CollapsibleArrow from '../../assets/icons/table-row-collapsible.svg';
import ViewGraphModal from './components/view-graph-modal';
import { AppContext, DispatcherAction } from 'context';
import { Spinner } from 'baseui/spinner';
import informationIcon from 'assets/icons/infomation_i_icon.png';

interface visibleTableBodyProps {
  [key: number]: boolean;
}

type TableColumnsHide = {
  show_all: boolean;
  gross_sale: boolean;
  net_sale: boolean;
};

const CogsPattern = () => {
  const [css] = useStyletron();
  const { dispatcher } = useContext(AppContext);
  const [visibleTableBody, setVisibleTableBody] =
    useState<visibleTableBodyProps>({});
  const [tableData, setTableData] = useState<any>([]);
  const [tableColumnsHide, setTableColumnsHide] = useState<TableColumnsHide>({
    show_all: true,
    gross_sale: true,
    net_sale: true,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initialSortDone, setInitialSortDone] = useState<boolean>(false);

  const BREADCRUMB_ITEMS = [
    { name: 'Analytics', route: '/analytics' },
    { name: 'Food Cost', route: '/foodcost' },
    { name: 'COGS Pattern', route: '/cogs-pattern' },
  ];

  useLayoutEffect(() => {
    dispatcher(DispatcherAction.BreadCrumbs, BREADCRUMB_ITEMS);
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const id = parseInt(entry.target.getAttribute('data-id') || '0', 10);
          setVisibleTableBody((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    // Observe each nested thead
    document.querySelectorAll('.nested-data').forEach((elem) => {
      observer.observe(elem);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!tableColumnsHide?.gross_sale && !tableColumnsHide?.net_sale) {
      setTableColumnsHide((prevState) => ({
        ...prevState,
        show_all: false,
      }));
    }

    if (tableColumnsHide?.gross_sale && tableColumnsHide?.net_sale) {
      setTableColumnsHide((prevState) => ({
        ...prevState,
        show_all: true,
      }));
    }
  }, [
    tableColumnsHide.show_all,
    tableColumnsHide.gross_sale,
    tableColumnsHide.net_sale,
  ]);

  const handleCollapsibleBody = (id: number, status: any) => {
    setVisibleTableBody((prevState) => ({
      ...prevState,
      [id]: status ?? !prevState[id],
    }));
  };

  useEffect(() => {
    if (tableData) {
      for (let i = 0; i < tableData?.[0]?.data?.length; i++) {
        handleCollapsibleBody(i, true);
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (tableData.length > 0 && !initialSortDone) {
      handleSort('period0.net_sales', 'desc');
      setInitialSortDone(true);
    }
    // eslint-disable-next-line
  }, [tableData, initialSortDone]);

  const mainWapper = css({
    padding: '16px',
  });

  const cellDarkGray = css({
    backgroundColor: '#e8e8ff',
  });
  const CellLightGray = css({
    backgroundColor: '#eff0ff',
  });
  const stickyColumn = css({
    position: 'sticky',
    left: 0,
    zIndex: 10,
    backgroundColor: '#fff',
    backgroundClip: 'padding-box', // Ensure background stays within padding
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)',
  });

  const arrangeIconStyle = css({
    position: 'absolute',
    top: '50%',
    right: '4.5px',
    transform: 'translateY(-50%)',
  });
  const collapsibleArrowStyle = css({
    position: 'relative',
    top: '50%',
    transition: '0.3s all ease-in',
  });
  const storeTitle = css({
    paddingLeft: '8px',
  });
  const pointerCursor = css({
    cursor: 'pointer',
  });
  const loader = css({
    height: 'calc(100vh - 380px)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noOfPattern, setNoOfPattern] = useState<any>(3);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  }>({ key: 'period0.net_sales', direction: 'desc' });

  const handleSort = (key: string, direction: 'asc' | 'desc' = 'asc') => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const [period, metric] = key.split('.');
    setSortConfig({ key, direction });
    const sortedData = sortData(tableData[0].data, period, metric, direction);
    setTableData([{ ...tableData[0], data: sortedData }]);
  };

  const sortData = (
    data: any[],
    period: string,
    metric: string,
    direction: 'asc' | 'desc'
  ) => {
    const parseValue = (value: string) => {
      if (value === undefined) return NaN;
      return parseFloat(value.replace(/,/g, '').replace('%', ''));
    };

    const compare = (a: any, b: any) => {
      const aValue =
        a.monthly_data[parseInt(period.replace('period', ''))][metric];
      const bValue =
        b.monthly_data[parseInt(period.replace('period', ''))][metric];

      const aNum = parseValue(aValue);
      const bNum = parseValue(bValue);

      if (aNum < bNum) return direction === 'asc' ? -1 : 1;
      if (aNum > bNum) return direction === 'asc' ? 1 : -1;
      return 0;
    };

    const sortedBrands = [...data].sort(compare);

    sortedBrands.forEach((brand: any) => {
      if (brand.data && brand.data.length > 0) {
        brand.data.sort((a: any, b: any) => {
          const aValue =
            a.monthly_stores_data[parseInt(period.replace('period', ''))][
              metric
            ];
          const bValue =
            b.monthly_stores_data[parseInt(period.replace('period', ''))][
              metric
            ];

          const aNum = parseValue(aValue);
          const bNum = parseValue(bValue);

          if (aNum < bNum) return direction === 'asc' ? -1 : 1;
          if (aNum > bNum) return direction === 'asc' ? 1 : -1;
          return 0;
        });
      }
    });

    return sortedBrands;
  };

  return (
    <>
      <div className={`${mainWapper} font-family-poppins`}>
        <AnalyticsFilters
          setTableData={(data: any) => {
            setInitialSortDone(false);
            setTableData(data);
          }}
          setIsLoading={setIsLoading}
          setNoOfPattern={setNoOfPattern}
          isCogsPattern={true}
          handleSort={handleSort}
          isLoading={isLoading}
        />

        <div className="cogs-table-wrapper">
          {isLoading ? (
            <div className={loader}>
              <Spinner $size="50" />
            </div>
          ) : tableData.length > 0 ? (
            <table className="cogs-table cogs-table-pattern">
              <thead>
                {tableData?.[0]?.columns && (
                  <>
                    <tr className="text-center">
                      <th
                        rowSpan={2}
                        className={`${stickyColumn} first-col`}
                        style={{ position: 'sticky' }}
                      >
                        {tableData?.[0]?.columns?.name}
                      </th>
                      {Array.from({ length: noOfPattern }).map((_, index) => (
                        <th
                          key={`header-period-${index}`}
                          style={{
                            width: noOfPattern >= 6 ? '450px' : '100%',
                          }}
                          colSpan={5}
                        >
                          {
                            tableData?.[0]?.columns[`period${index + 1}`]
                              ?.period
                          }
                          {/* Add the small "i" icon */}
                          <span
                            style={{
                              marginLeft: '8px',
                              position: 'relative',
                              cursor: 'pointer',
                              display: 'inline-block',
                              top: '4px', // Ensures the icon stays in a proper layout
                            }}
                            onMouseEnter={(e) => {
                              const tooltip: any =
                                e.currentTarget.querySelector(
                                  '.tooltip-content'
                                );
                              if (tooltip) {
                                tooltip.style.visibility = 'visible';
                                tooltip.style.opacity = '1';
                              }
                            }}
                            onMouseLeave={(e) => {
                              const tooltip: any =
                                e.currentTarget.querySelector(
                                  '.tooltip-content'
                                );
                              if (tooltip) {
                                tooltip.style.visibility = 'hidden';
                                tooltip.style.opacity = '0';
                              }
                            }}
                          >
                            {/* Styled "i" icon as a circle */}
                            <span
                              style={{
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '18px', // Adjust as needed
                                height: '18px', // Adjust as needed
                                cursor: 'pointer',
                                transition: 'background-color 0.2s ease',
                              }}
                            >
                              <img
                                src={informationIcon} // Update with the actual path to the image
                                alt="Info icon"
                                style={{
                                  width: '100%', // Fit within the outer circle container
                                  height: '100%', // Fit within the outer circle container
                                  borderRadius: '50%', // Optional: keeps a circular shape
                                }}
                              />
                            </span>

                            {/* Tooltip that shows on hover */}
                            <span
                              style={{
                                position: 'absolute',
                                top: '120%',
                                left: '130%', // Move the tooltip to the right
                                transform: 'translateY(-50%)', // Center the tooltip vertically
                                marginLeft: '10px', // Space between icon and tooltip
                                backgroundColor: '#000',
                                color: '#fff',
                                padding: '5px',
                                borderRadius: '4px',
                                whiteSpace: 'pre-line', // Allow line breaks
                                visibility: 'hidden',
                                opacity: 0,
                                transition: 'opacity 0.3s ease',
                                fontSize: '10px',
                                width: '150px',
                                zIndex: '10000', // Ensure tooltip is on top of other elements
                                fontStyle: 'italic',
                              }}
                              className="tooltip-content"
                            >
                              {`Sales last fetched at:\n ${
                                tableData?.[0]?.data[0]?.monthly_data?.[index]
                                  ?.batch?.saleLastFetchedAt || 'N/A'
                              }\nCogs last fetched at:\n ${
                                tableData?.[0]?.data[0]?.monthly_data?.[index]
                                  ?.batch?.cogsLastFetchedAt || 'N/A'
                              }`}
                            </span>
                          </span>
                        </th>
                      ))}
                    </tr>
                    <tr className="text-left second-row">
                      {Array.from({ length: noOfPattern }).map((_, index) => (
                        <Fragment key={`store-columns-${index}`}>
                          <th
                            style={{ width: '100%' }}
                            className={`${
                              index % 2 === 0 ? CellLightGray : cellDarkGray
                            } ${pointerCursor}`}
                            onClick={() => handleSort(`period${index}.g_sales`)}
                          >
                            <span>
                              {
                                tableData?.[0]?.columns[`period${index + 1}`]
                                  ?.g_sales
                              }
                            </span>
                            <img
                              src={ArrangeIcon}
                              alt=""
                              className={arrangeIconStyle}
                              width="10"
                              height="10"
                            />
                          </th>
                          <th
                            style={{ width: '100%' }}
                            className={`${
                              index % 2 === 0 ? CellLightGray : cellDarkGray
                            } ${pointerCursor}`}
                            onClick={() =>
                              handleSort(`period${index}.net_sales`)
                            }
                          >
                            <span>
                              {
                                tableData?.[0]?.columns[`period${index + 1}`]
                                  ?.net_sales
                              }
                            </span>
                            <img
                              src={ArrangeIcon}
                              alt=""
                              className={arrangeIconStyle}
                              width="10"
                              height="10"
                            />
                          </th>
                          <th
                            style={{ width: '100%' }}
                            className={`${
                              index % 2 === 0 ? CellLightGray : cellDarkGray
                            } ${pointerCursor}`}
                            onClick={() => handleSort(`period${index}.cost`)}
                          >
                            <span>
                              {
                                tableData?.[0]?.columns[`period${index + 1}`]
                                  ?.cost
                              }
                            </span>
                            <img
                              src={ArrangeIcon}
                              alt=""
                              className={arrangeIconStyle}
                              width="10"
                              height="10"
                            />
                          </th>
                          {tableColumnsHide?.gross_sale && (
                            <th
                              style={{ width: '100%' }}
                              className={`${
                                index % 2 === 0 ? CellLightGray : cellDarkGray
                              } ${pointerCursor}`}
                              onClick={() =>
                                handleSort(`period${index}.cost_on_gross`)
                              }
                            >
                              <span style={{ fontWeight: 'bold' }}>
                                {
                                  tableData?.[0]?.columns[`period${index + 1}`]
                                    ?.cost_on_gross
                                }
                              </span>
                              <img
                                src={ArrangeIcon}
                                alt=""
                                className={arrangeIconStyle}
                                width="10"
                                height="10"
                              />
                            </th>
                          )}
                          {tableColumnsHide?.net_sale && (
                            <th
                              style={{ width: '100%' }}
                              className={`${
                                index % 2 === 0 ? CellLightGray : cellDarkGray
                              } ${pointerCursor}`}
                              onClick={() =>
                                handleSort(`period${index}.cost_on_net`)
                              }
                            >
                              <span style={{ fontWeight: 'bold' }}>
                                {
                                  tableData?.[0]?.columns[`period${index + 1}`]
                                    ?.cost_on_net
                                }
                              </span>
                              <img
                                src={ArrangeIcon}
                                alt=""
                                className={arrangeIconStyle}
                                width="10"
                                height="10"
                              />
                            </th>
                          )}
                        </Fragment>
                      ))}
                    </tr>
                  </>
                )}
              </thead>
              <tbody>
                {tableData?.[0].data.map((brand: any, id: any) => (
                  <tr key={id}>
                    <td colSpan={17} className="tdreset">
                      <table className="cogs-table">
                        <thead
                          className="nested-data"
                          onClick={() => handleCollapsibleBody(id, null)}
                          data-id={id}
                        >
                          <tr className=" main-row">
                            <td className={`${stickyColumn} first-col`}>
                              <img
                                src={CollapsibleArrow}
                                alt=""
                                className={collapsibleArrowStyle}
                                style={{
                                  transform: visibleTableBody[id]
                                    ? 'rotate(0deg)'
                                    : 'rotate(180deg)',
                                }}
                              />
                              <span className={storeTitle}>{brand.name}</span>
                            </td>
                            {brand.monthly_data.map((data: any, index: any) => (
                              <Fragment key={`monthly-data-${index}`}>
                                <td
                                  className="number-cell"
                                  style={{
                                    width:
                                      noOfPattern === 12 ||
                                      noOfPattern === 9 ||
                                      noOfPattern === 6
                                        ? '90px'
                                        : '100%',
                                  }}
                                >
                                  {data.g_sales}
                                </td>
                                <td
                                  className="number-cell"
                                  style={{
                                    width:
                                      noOfPattern === 12 ||
                                      noOfPattern === 9 ||
                                      noOfPattern === 6
                                        ? '90px'
                                        : '100%',
                                  }}
                                >
                                  {data.net_sales}
                                </td>
                                <td
                                  className="number-cell"
                                  style={{
                                    width:
                                      noOfPattern === 12 ||
                                      noOfPattern === 9 ||
                                      noOfPattern === 6
                                        ? '90px'
                                        : '100%',
                                  }}
                                >
                                  {data.cost}
                                </td>
                                {tableColumnsHide?.gross_sale && (
                                  <td
                                    className="number-cell"
                                    style={{
                                      fontWeight: 'bold',
                                      width:
                                        noOfPattern === 12 ||
                                        noOfPattern === 9 ||
                                        noOfPattern === 6
                                          ? '90px'
                                          : '100%',
                                    }}
                                  >
                                    {data.cost_on_gross}
                                  </td>
                                )}
                                {tableColumnsHide?.net_sale && (
                                  <td
                                    className="number-cell"
                                    style={{
                                      fontWeight: 'bold',
                                      width:
                                        noOfPattern === 12 ||
                                        noOfPattern === 9 ||
                                        noOfPattern === 6
                                          ? '90px'
                                          : '100%',
                                    }}
                                  >
                                    {data.cost_on_net}
                                  </td>
                                )}
                              </Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            display: visibleTableBody[id] ? '' : 'none',
                          }}
                          className={visibleTableBody[id] ? 'add' : 'remove'}
                        >
                          {brand.data.map((store: any, storeIndex: any) => (
                            <tr key={`store-data-${storeIndex}`}>
                              <td
                                style={{ width: '150px' }}
                                className={`${stickyColumn} first-col`}
                              >
                                {store.name}
                              </td>
                              {store.monthly_stores_data.map(
                                (storeData: any, storeDataIndex: any) => (
                                  <Fragment
                                    key={`store-monthly-data-${storeDataIndex}`}
                                  >
                                    <td className="number-cell">
                                      {storeData.g_sales}
                                    </td>
                                    <td className="number-cell">
                                      {storeData.net_sales}
                                    </td>
                                    <td className="number-cell">
                                      {storeData.cost}
                                    </td>
                                    {tableColumnsHide?.gross_sale && (
                                      <td
                                        className="number-cell"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        {storeData.cost_on_gross}
                                      </td>
                                    )}
                                    {tableColumnsHide?.net_sale && (
                                      <td
                                        className="number-cell"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        {storeData.cost_on_net}
                                      </td>
                                    )}
                                  </Fragment>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
                {tableData?.[0]?.total && (
                  <tr>
                    <td colSpan={17} className="tdreset pt-30">
                      <table className="cogs-table total-table">
                        <thead className="nested-data text-center">
                          <tr className="active">
                            <td className={`${stickyColumn} first-col`}>
                              Total
                            </td>
                            {tableData?.[0].total.map(
                              (data: any, index: any) => (
                                <Fragment key={`total-data-${index}`}>
                                  <td
                                    className="number-cell"
                                    style={{
                                      width:
                                        noOfPattern === 12 ||
                                        noOfPattern === 9 ||
                                        noOfPattern === 6
                                          ? '90px'
                                          : '100%',
                                    }}
                                  >
                                    {data.g_sales}
                                  </td>
                                  <td
                                    className="number-cell"
                                    style={{
                                      width:
                                        noOfPattern === 12 ||
                                        noOfPattern === 9 ||
                                        noOfPattern === 6
                                          ? '90px'
                                          : '100%',
                                    }}
                                  >
                                    {data.net_sales}
                                  </td>
                                  <td
                                    className="number-cell"
                                    style={{
                                      width:
                                        noOfPattern === 12 ||
                                        noOfPattern === 9 ||
                                        noOfPattern === 6
                                          ? '90px'
                                          : '100%',
                                    }}
                                  >
                                    {data.cost}
                                  </td>
                                  {tableColumnsHide?.gross_sale && (
                                    <td
                                      className="number-cell"
                                      style={{
                                        fontWeight: 'bold',
                                        width:
                                          noOfPattern === 12 ||
                                          noOfPattern === 9 ||
                                          noOfPattern === 6
                                            ? '90px'
                                            : '100%',
                                      }}
                                    >
                                      {data.cost_on_gross}
                                    </td>
                                  )}
                                  {tableColumnsHide?.net_sale && (
                                    <td
                                      className="number-cell"
                                      style={{
                                        fontWeight: 'bold',
                                        width:
                                          noOfPattern === 12 ||
                                          noOfPattern === 9 ||
                                          noOfPattern === 6
                                            ? '90px'
                                            : '100%',
                                      }}
                                    >
                                      {data.cost_on_net}
                                    </td>
                                  )}
                                </Fragment>
                              )
                            )}
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            'No Data Found'
          )}
        </div>
      </div>

      <ViewGraphModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default CogsPattern;
