import React from 'react';
import { useStyletron } from 'styletron-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Toolbar from 'modules/shared/components/navigation/toolbar';
import SideBar from 'modules/shared/components/navigation/sidebar';
import { AppContext, DispatcherAction } from 'context';
import { event } from 'core/events';
import {
  ON_JWT_EXPIRED,
  ON_JWT_REFRESHED,
  ON_NETWORK_ERROR,
  ON_SERVER_ERROR,
} from 'constants/events';
import { AuthService } from 'core/services/auth';
import { urls } from 'constants/urls';

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const {
    state: { token },
    dispatcher,
  } = React.useContext(AppContext);
  const [isActiveSidebar, setIsActiveSidebar] = React.useState(false);
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [hasServerError, setHasServerError] = React.useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false);
  const [css] = useStyletron();

  const authService = new AuthService();
  const navigate = useNavigate();
  const rootWrapper = css({ display: 'flex' });
  const sidebarWrapper = css({
    display: 'block',
    width: '100%',
    maxWidth: '270px',
    position: 'fixed',
    transition: 'all 0.3s ease-in-out',
    '@media (max-width: 1000px)': {
      maxWidth: '100vw',
    },
    '@media (max-width: 500px)': {
      display: `${isActiveSidebar ? 'block' : 'none'}`,
      left: `${isActiveSidebar ? '0px' : '-550px'}`,
      maxWidth: '100vw',
      zIndex: 400,
    },
  });

  const mainWrapper = css({
    display: 'block',
    width: isSidebarOpen ? 'calc(100% - 190px)' : 'calc(100% - 100px)',
    marginLeft:
      isAuthenticated && !hasServerError
        ? !isSidebarOpen
          ? '100px'
          : urls?.includes(location?.pathname)
          ? '190px'
          : '190px'
        : 'unset',
    transition: 'all 400ms ease',
    '@media (max-width: 1000px)': {
      marginTop: '30px',
    },
  });

  const jWTExpiredHandler = () => {
    authService.setAuthToken('');
    dispatcher(DispatcherAction.Logout);
  };

  const jWTRefreshedHandler = () => {
    dispatcher(DispatcherAction.JWTRefresh);
  };

  const networkErrorHandler = () => {
    setHasServerError(true);
    navigate('error');
  };

  const serverErrorHandler = () => {
    setHasServerError(true);
    navigate('error');
  };

  React.useEffect(() => {
    setIsAuthenticated(token && token !== '');
  }, [token]);

  React.useEffect(() => {
    document.body.style.background = 'white';
  }, [location]);

  React.useEffect(() => {
    event.subscribe(ON_JWT_EXPIRED, jWTExpiredHandler);
    event.subscribe(ON_JWT_REFRESHED, jWTRefreshedHandler);
    event.subscribe(ON_NETWORK_ERROR, networkErrorHandler);
    event.subscribe(ON_SERVER_ERROR, serverErrorHandler);

    return () => {
      event.unsubscribe(ON_JWT_EXPIRED, jWTExpiredHandler);
      event.unsubscribe(ON_JWT_REFRESHED, jWTRefreshedHandler);
      event.unsubscribe(ON_NETWORK_ERROR, networkErrorHandler);
      event.unsubscribe(ON_SERVER_ERROR, serverErrorHandler);
    };
  });

  return (
    <React.Fragment>
      {isAuthenticated && !hasServerError ? (
        <>
          <Toolbar
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </>
      ) : null}
      <div className={rootWrapper}>
        {isAuthenticated && !hasServerError ? (
          <div
            className={sidebarWrapper}
            onClick={() => setIsActiveSidebar(!isActiveSidebar)}
          >
            <SideBar
              setIsSidebarOpen={setIsSidebarOpen}
              isSidebarOpen={false}
            />
          </div>
        ) : null}
        <div className={mainWrapper}>
          <main>{children}</main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
