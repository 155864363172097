import React from 'react';
import { useStyletron } from 'styletron-react';

import Breadcrumb from 'modules/shared/components/breadcrumb';
import reportsIconURL from 'assets/icons/reports.svg';
import CustomAccordion from 'modules/shared/components/accordion';
import { AccordionGroup } from 'modules/shared/interfaces/master-items.interface';

const BREADCRUMB_ITEMS = [{ name: 'Reports', route: 'reports' }];

const reportsList: AccordionGroup[] = [
  {
    title: 'Order Reports',
    items: [
      {
        id: 1,
        title: 'Orders List',
        route: '/reports/orderListReport',
      },
      {
        id: 2,
        title: 'Purchase Contribution',
        route: '/reports/purchaseContributionReport',
      },
      {
        id: 3,
        title: 'Delivery Report',
        route: '/reports/deliverTodayReport',
      },
    ],
  },
  {
    title: 'Invoice Reports',
    items: [
      {
        id: 2,
        title: 'Invoice List',
        route: '/reports/invoiceListReport',
      },
      {
        id: 3,
        title: 'Daily Receiving Report (DRR)',
        route: '/reports/dailyRecievingInvoiceReport',
      },
    ],
  },
  {
    title: 'Requisition Reports',
    items: [
      {
        id: 3,
        title: 'Requisition List',
        route: '/reports/requisitionListReport',
      },
    ],
  },
];

const ReportsList: React.FC = () => {
  const [css] = useStyletron();

  const reportsListWrapper = css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0 16px',
  });
  const breadcrumbWrapper = css({
    display: 'flex',
    padding: '16px 16px 0',
    alignItems: 'center',
  });
  const iconStyles = css({
    width: '20px',
    height: '20px',
    filter:
      'invert(31%) sepia(61%) saturate(308%) hue-rotate(181deg) brightness(90%) contrast(93%)',
  });

  return (
    <div>
      <div className={breadcrumbWrapper}>
        <img className={iconStyles} src={reportsIconURL} alt="Reports" />
        <Breadcrumb items={BREADCRUMB_ITEMS} />
      </div>
      <div className={reportsListWrapper}>
        <CustomAccordion itemList={reportsList} />
      </div>
    </div>
  );
};

export default ReportsList;
