import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import { AppContext, DispatcherAction } from 'context';
import { AuthService } from 'core/services/auth';
import colors from 'constants/colors';
import { Axios } from 'core/services/http';

const CustomPopover = ({
  children = null,
  content = null,
  placement = 'top',
}: {
  children?: React.ReactNode;
  content?: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [css] = useStyletron();

  const togglePopover = () => setIsOpen(!isOpen);
  const closePopover = () => setIsOpen(false);

  const popoverContent = css({
    position: 'absolute',
    [placement]: '2.8rem',
    left: '-4rem',
    backgroundColor: colors.white,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '8px 0px',
    zIndex: 1000,
    borderRadius: '5px',
  });

  const wrapperStyles = css({
    position: 'relative',
    cursor: 'pointer',
  });

  return (
    <div
      className={wrapperStyles}
      ref={popoverRef}
      onClick={togglePopover}
      onBlur={(event) => {
        if (!popoverRef.current?.contains(event.relatedTarget)) {
          closePopover();
        }
      }}
      tabIndex={0}
    >
      {children}
      {isOpen && <div className={popoverContent}>{content}</div>}
    </div>
  );
};

const UserProfile = ({ children = null }: { children?: React.ReactNode }) => {
  const { dispatcher } = useContext(AppContext);
  const [css] = useStyletron();
  const navigate = useNavigate();
  const authService = new AuthService();
  const axios = new Axios().getInstance();

  const logoutClickedHandler = async () => {
    try {
      localStorage.removeItem('selectedCompanies');
      await axios.post('/auth/signOut');
      authService.logout();
      dispatcher(DispatcherAction.Logout);
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  const myAccountBtn = css({
    width: '90px',
    cursor: 'pointer',
    padding: '12px 16px',
    margin: 'auto',
    ':hover': {
      backgroundColor: colors.primaryBlue,
      color: colors.white,
    },
  });

  const userWrapper = css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  });

  const popoverContent = (
    <div>
      <p className={myAccountBtn} onClick={() => navigate('/my-account')}>
        My Account
      </p>
      <p className={myAccountBtn} onClick={logoutClickedHandler}>
        Logout
      </p>
    </div>
  );

  return (
    <CustomPopover content={popoverContent}>
      <div className={userWrapper}>{children}</div>
    </CustomPopover>
  );
};

export default UserProfile;
