import React from 'react';
import Icon from './icon';
import { useStyletron } from 'styletron-react';
type Prop = {
  sortOption: string;
};
const Sort = ({ sortOption }: Prop) => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      })}
    >
      {/* <Label sortOption={sortOption} /> */}
      <Icon />
    </div>
  );
};

export default Sort;
