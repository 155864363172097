import React, { useContext } from 'react';
import { toaster } from 'baseui/toast';
import './index.scss';
import { Axios } from 'core/services/http';
import { AppContext } from 'context';
import { WebSocketContext } from 'context/webSocket';

function CustomToastContent({
  title,
  message,
  buttonText,
  clicked,
}: {
  title: string;
  message: string;
  buttonText: string;
  clicked: () => void;
}) {
  return (
    <div className="custom-toast-notification">
      <div className="custom-toast-notification__title">{title}</div>
      <div className="custom-toast-notification__body">{message}</div>
      <div className="custom-toast-notification__action">
        <button type="button" onClick={clicked}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}

interface IProps {
  refetch: () => void;
}

const NotificationListener = ({ refetch }: IProps) => {
  const {
    state: { notificationPopupEnabled },
  } = React.useContext(AppContext);
  const axios = new Axios().getInstance();
  const user = JSON.parse(localStorage.getItem('auth-user') || '{}');
  const { socket } = useContext(WebSocketContext);

  const markNotificationsAsRead = async (notificationIds: Array<string>) => {
    try {
      const response = await axios.patch('/notifications/read', {
        notificationIds,
      });
      if (response?.data?.success) {
        refetch();
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors?.length) {
        errors.forEach((message: string) => {
          console.log(message);
        });
      }
    }
  };

  React.useEffect(() => {
    if (socket) {
      socket.connect();
      socket.on('new-notification', (response) => {
        response.notifications.forEach((notification: any) => {
          if (notification.recipient === user.id) {
            const {
              title,
              message,
              data: { id },
              link,
              type,
              isError,
            } = notification;
            if (isError) {
              // showErrorNotification(title, message, id, link, type);
              toaster.negative(message, {
                autoHideDuration: 8000,
              });
            } else {
              // Handle regular notifications
              showNotification(title, message, id, link, type);
            }
            // showNotification(title, message, id, link, type);
          }
        });
      });

      return () => {
        socket.off('new-notification');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const showNotification = (
    title: string,
    message: string,
    id: string,
    link: string,
    type: string
  ) => {
    if (notificationPopupEnabled) {
      const toasterKey = toaster.positive(
        <CustomToastContent
          title={title}
          message={message}
          buttonText={`View ${type}`}
          clicked={() => {
            markNotificationsAsRead([id]);
            window.open(link, '_blank');
            toaster.clear(toasterKey);
          }}
        />,
        { autoHideDuration: 4000 }
      );
    }
  };

  return null;
};

export default NotificationListener;
