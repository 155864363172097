import { Axios } from 'core/services/http';
import React, { useEffect, useState } from 'react';

const useFilterLayoutData = () => {
  const [stores, setStores] = useState<Array<any>>([]);
  const [store, setStore] = React.useState<Array<any>>([]);
  const [brands, setBrandsList] = useState<Array<any>>([]);
  const [brand, setBrand] = useState<Array<any>>([]);
  const axios = new Axios().getInstance();
  const getStores = async () => {
    try {
      const response = await axios.get('/stores');
      if (response?.data?.success) {
        const storeIDs: Array<any> = [];
        const stores = response.data.data.map((store: any) => {
          storeIDs.push(store.id);
          return {
            id: store.id,
            label: store.name,
          };
        });

        setStores(stores);
        setStore(storeIDs);
      }
    } catch (error: any) {}
  };
  const getBrands = async () => {
    const response = await axios.get('/brands', {
      params: { fromDashboard: true, status: 'active' },
    });
    if (response?.data?.success) {
      setBrandsList(response.data.data);
      setBrand(response.data.data);
    }
  };
  const fetchData = async () => {
    await Promise.all([getStores(), getBrands()]);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    store,
    setStore,
    brand,
    setBrand,
    stores,
    brands,
    getStores,
    getBrands,
  };
};

export default useFilterLayoutData;
